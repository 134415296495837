import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, IconButton } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { AuthContext } from 'contexts/AuthContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import styles from '../styles';

const Serviço = ({
  drops,
  loading,
  onSubmit,
  documento = {},
  disableActions,
}) => {
  const defaultValues = {
    id: documento?.id,
    filial_id: documento?.filial_id || null,
    cadastro_id: documento?.cadastro_id || null,
    dtemissao: documento?.dtemissao || null,
    documento: documento?.documento || '',
    observacao: documento?.observacao || '',
  };
  const { control, watch, handleSubmit } = useForm({ defaultValues });
  const { user } = useContext(AuthContext);

  return (
    <Card title="Documento" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Dropdown
            name="filial_id"
            control={control}
            label="Filial"
            options={drops?.Filial}
            disabled={user?.nivel === 'TÉCNICO'}
          />
        </Grid>
        <Grid item xs={10}>
          <VirtualDrop
            name="cadastro_id"
            control={control}
            label="Entidade"
            options={drops?.Entidade}
            disabled={user?.nivel === 'TÉCNICO'}
          />
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            color="primary"
            onClick={() =>
              window.open(
                `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                  'cadastro_id'
                )}`
              )
            }
            disabled={!Boolean(watch('cadastro_id'))}
          >
            <Visibility />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="dtemissao"
            control={control}
            label="Data de Emissão"
            type="datetime"
            disabled={user?.nivel === 'TÉCNICO'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="documento"
            control={control}
            label="Documento"
            type="number"
            disabled={user?.nivel === 'TÉCNICO'}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="observacao"
            control={control}
            label="Observação"
            multiline
            rows={5}
            disabled={user?.nivel === 'TÉCNICO'}
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={handleSubmit((Documento) => onSubmit({ Documento }))}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Serviço;
