const styles = {
  card: {
    marginTop: '1em',
  },
  image: {
    objectFit: 'contain',
    maxHeight: '300px',
    overflow: 'hidden',
    width: '100%',
  },
  pdf: {
    textAlign: 'center',
    maxHeight: '300px',
    overflow: 'hidden',
    width: '100%',
    padding: '2em 0',
  },
  button: {
    margin: '0 .5em',
  },
};

export default styles;
