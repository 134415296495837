import React, { useContext, useState } from 'react';
import { Edit, Add, Upload, Close, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { OrdensContext } from 'contexts/OrdensContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { Grid, IconButton, Typography, Checkbox } from '@mui/material';
import { toastWarning } from 'utils/toast';
import { dropTipoServico } from 'utils/drops';
import { findOnArray } from 'utils/functions';
import { useDialog, useModal } from 'components/Modals';
import EntidadeModal from 'components/Modals/EntidadeModal';
import EquipamentoModal from 'components/Modals/FullEquipamentoModal';
import PesquisaEquipamentoModal from 'components/Modals/PesquisaEquipamentoModal';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import moment from 'moment';
import styles from './styles';

const OrdemEquipamentos = () => {
  const titulo = 'Ordem de Serviço';
  const defaultValues = {
    cadastro_id: null,
    aprovacao: false,
    tipo: 'CORRETIVA',
    sn: '',
    equipamento: null,
    relato: '',
    acessorios: '',
  };
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const { openDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });
  const { postLoading, postDocumento, postDocumentoMidia } =
    useContext(OrdensContext);
  const { drops } = useContext(DropsContext);
  const { user } = useContext(AuthContext);

  const onSubmit = (values) => {
    if (!Boolean(values?.cadastro_id)) {
      return toastWarning('Entidade obrigatória');
    } else if (!Boolean(values?.equipamento)) {
      return toastWarning('Equipamento obrigatório');
    } else {
      const data = {
        Documento: {
          especie_id: 16,
          natureza_operacao_id: 11,
          filial_id: user?.filial_id,
          cadastro_id: values?.cadastro_id,
          dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
          users_id: user?.id,
        },
        Servico: {
          tipo: values?.tipo,
        },
        Equipamentos: [
          {
            equipamento_id: values?.equipamento,
            sn: values?.sn,
            relatocli: values?.relato,
            acessorios: values?.acessorios,
          },
        ],
        Itens: [
          {
            sequencia: 1,
            descricao: `Manutenção`,
            quantidade: 1,
            Processos: [
              {
                operacao: 10,
                descricao: `Manutenção`,
                apontamento: 'SIM',
              },
            ],
          },
        ],
        Registros: [
          {
            users_id: user?.id,
            datahora: moment().format('YYYY-MM-DD HH:mm:ss'),
            status_id: values?.aprovacao ? 18 : 16,
          },
        ],
      };
      if (Boolean(file)) {
        const formData = new FormData();
        formData.append('documento', JSON.stringify(data));
        formData.append('midia', file);
        postDocumentoMidia({ data: formData, cb: () => navigate(-1) });
      } else {
        postDocumento({ data, cb: () => navigate(-1) });
      }
    }
  };

  const onSearchEquipamento = (values) => {
    setValue('sn', values?.sn);
    setValue('equipamento', values?.equipamento_id);
    closeModal();
  };

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" alignItems="center">
            <Checkbox
              color="primary"
              checked={watch('aprovacao')}
              onChange={() => setValue('aprovacao', !watch('aprovacao'))}
            />
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
              Aprovar
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {Boolean(watch('cadastro_id')) && (
              <IconButton
                color="primary"
                onClick={() =>
                  window.open(
                    `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                      'cadastro_id'
                    )}`
                  )
                }
              >
                <Visibility />
              </IconButton>
            )}
            {Boolean(watch('cadastro_id')) && (
              <IconButton
                color="primary"
                onClick={() =>
                  openDialog(
                    <EntidadeModal
                      entidade_id={watch('cadastro_id')}
                      callback={(id) => setValue('cadastro_id', id)}
                    />,
                    'Editar Entidade'
                  )
                }
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              color="primary"
              onClick={() =>
                openDialog(
                  <EntidadeModal
                    callback={(id) => setValue('cadastro_id', id)}
                  />,
                  'Cadastrar Entidade'
                )
              }
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="tipo"
              control={control}
              label="Tipo do Serviço"
              options={dropTipoServico}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" align="center">
              Dados do Equipamento
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                openModal(
                  <PesquisaEquipamentoModal onSubmit={onSearchEquipamento} />,
                  90
                )
              }
            >
              Pesquisar Equipamento
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="sn" control={control} label="Serial Number" />
          </Grid>
          <Grid item xs={10} sm={6}>
            <VirtualDrop
              name="equipamento"
              control={control}
              label="Equipamento"
              options={drops?.Equipamento}
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {Boolean(watch('equipamento')) && (
              <IconButton
                color="primary"
                onClick={() =>
                  openDialog(
                    <EquipamentoModal
                      equipamento={findOnArray(
                        watch('equipamento'),
                        drops?.Equipamento
                      )}
                      callback={(id) => setValue('equipamento', id)}
                    />,
                    'Editar Equipamento'
                  )
                }
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              color="primary"
              onClick={() =>
                openDialog(
                  <EquipamentoModal
                    callback={(id) => setValue('equipamento', id)}
                  />,
                  'Cadastrar Equipamento'
                )
              }
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Input name="relato" control={control} label="Relato do Cliente" />
          </Grid>
          <Grid item xs={12}>
            <Input name="acessorios" control={control} label="Acessórios" />
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Upload />}
              onClick={() => document.getElementById('upload-midia')?.click()}
            >
              <input
                id="upload-midia"
                hidden
                accept="image/*"
                type="file"
                onChange={({ target }) => {
                  setFile(target.files[0] || null);
                  target.value = '';
                }}
              />
              Upload
            </Button>
            {Boolean(file) && (
              <Typography
                variant="body2"
                sx={{ marginLeft: 1, color: 'primary.main' }}
              >
                {file?.name}
              </Typography>
            )}
            {Boolean(file) && (
              <IconButton onClick={() => setFile(null)} size="small">
                <Close />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={2} sx={styles?.container}>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            loading={postLoading}
          >
            Finalizar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OrdemEquipamentos;
