import React from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { dropStatus } from 'utils/drops';
import { useModal } from 'components/Modals';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

const AlteraStatusModal = ({ user, onSubmit, documento_id }) => {
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({
    defaultValues: { status_id: null },
  });

  const drop =
    user?.atividade_filial === 877
      ? dropStatus?.filter((f) => f?.value !== 6)
      : dropStatus;

  return (
    <>
      <DialogTitle>Alteração de Status da Ordem de Serviço</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Dropdown
              name="status_id"
              control={control}
              label="Status"
              options={drop}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit((v) => {
            onSubmit({ documento_id, ...v });
            closeModal();
          })}
        >
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default AlteraStatusModal;
