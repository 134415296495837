import React, { useState } from 'react';
import { Grid, Icon, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { summarizer } from 'utils/functions';
import Button from 'components/Button';
import moment from 'moment';

const ApontamentoModal = ({ processo, onDelete, onEdit }) => {
  const rotina = 'DocumentoItemProcessoApont';
  const finalizados = processo?.DocumentoItemProcessoAponts?.filter(
    (f) => Boolean(f?.inicio) && Boolean(f?.fim)
  ).map((m) => ({
    ...m,
    executante: m?.User?.name,
    inicio:
      m?.inicio &&
      moment(m?.inicio).isValid() &&
      moment(m?.inicio).format('DD/MM/YYYY [às] HH:mm'),
    fim:
      m?.fim &&
      moment(m?.fim).isValid() &&
      moment(m?.fim).format('DD/MM/YYYY [às] HH:mm'),
    tempo:
      moment.duration(moment(m?.fim).diff(moment(m?.inicio))).days() +
      'd:' +
      moment.duration(moment(m?.fim).diff(moment(m?.inicio))).hours() +
      'h:' +
      moment.duration(moment(m?.fim).diff(moment(m?.inicio))).minutes() +
      'm:' +
      moment.duration(moment(m?.fim).diff(moment(m?.inicio))).seconds() +
      's',
    tempo_total: moment
      .duration(moment(m?.fim).diff(moment(m?.inicio)))
      .asMilliseconds(),
  }));
  const [rows, setRows] = useState(finalizados);

  const getTotal = () => {
    const total = summarizer(finalizados, 'tempo_total');
    return (
      moment.duration(total).days() +
      'd:' +
      moment.duration(total).hours() +
      'h:' +
      moment.duration(total).minutes() +
      'm:' +
      moment.duration(total).seconds() +
      's'
    );
  };

  const onCellEditCommit = (e) => {
    const editedRows = rows?.map((c) => {
      if (c?.id === e?.id) {
        if (moment(e?.value, 'DD/MM/YYYY [às] HH:mm').isValid()) {
          return { ...c, [e?.field]: e?.value };
        } else {
          return { ...c };
        }
      } else {
        return { ...c };
      }
    });
    setRows(editedRows);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          {processo?.descricao}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={rows}
          columns={[
            {
              field: 'executante',
              headerName: 'Executante',
              flex: 3,
              sortable: false,
            },
            {
              field: 'inicio',
              headerName: 'Início',
              flex: 1,
              editable: true,
              sortable: false,
            },
            {
              field: 'fim',
              headerName: 'Fim',
              flex: 1,
              editable: true,
              sortable: false,
            },
            {
              field: 'tempo',
              headerName: 'Tempo',
              flex: 1,
              sortable: false,
            },
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              flex: 1,
              getActions: (params) => [
                <GridActionsCellItem
                  icon={<Icon>delete</Icon>}
                  label="Excluir"
                  onClick={() => onDelete({ rotina, id: params?.id })}
                />,
              ],
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
          onCellEditCommit={onCellEditCommit}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onEdit(rows, processo)}
        >
          Salvar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="div" align="center">
          Total Apontado: {getTotal()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ApontamentoModal;
