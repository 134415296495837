import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { summarizer, checkInfosProduto, encryptURL } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { OrdensContext } from 'contexts/OrdensContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import ButtonMenu from 'components/ButtonMenu';
import VirtualDrop from 'components/VirtualDrop';
import TableContainer from 'components/TableContainer';
import Blank from 'assets/blank.png';
import styles from '../styles';

const Itens = ({
  drops,
  onSubmit,
  onDelete,
  documento = {},
  disableActions,
}) => {
  const { openDialog } = useDialog();
  const [selected, setSelected] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const rotina = 'DocumentoItem';

  const equals = (a = [], b = []) =>
    a.length === b.length && a.every((v, i) => v === b[i]);

  const onSelectAll = () => {
    if (equals(selected, documento?.DocumentoItems)) {
      setSelected([]);
    } else {
      setSelected(documento?.DocumentoItems);
    }
  };

  const getTotalDocumento = () =>
    documento?.DocumentoItems?.length
      ? documento?.DocumentoItems?.map(
          (i) => i?.preco * i?.quantidade + i?.desconto_vlr
        )?.reduce((prev, next) => prev + next) || 0
      : 0;

  const Modal = ({ item }) => {
    const [additionals, setAdditionals] = useState(null);
    const [additionalLoading, setAdditionalsLoading] = useState(false);
    const { postLoading } = useContext(OrdensContext);
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      produto_id: item?.produto_id || null,
      descricao: item?.descricao || '',
      quantidade: item?.quantidade || '',
      unidade_medida_id: item?.unidade_medida_id || null,
      preco: item?.preco || '',
      desconto1_pc: item?.desconto1_pc || '',
      desconto_vlr: item?.desconto_vlr || '',
      referencia: item?.referencia || '',
      przent: item?.przent || null,
    };
    const { control, handleSubmit, setValue, watch } = useForm({
      defaultValues,
    });

    const getTotal = () =>
      (watch('quantidade') || 1) * watch('preco') + watch('desconto_vlr');

    useEffect(() => {
      setValue(
        'desconto_vlr',
        watch('desconto1_pc') * watch('preco') * watch('quantidade') * -0.01
      );
    }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <VirtualDrop
                name="produto_id"
                control={control}
                label="Produto"
                options={drops?.Produto}
                onValueChange={async (p) => {
                  setAdditionals(null);
                  if (p?.value) {
                    try {
                      setAdditionalsLoading(true);
                      const produto = await checkInfosProduto({
                        produto_id: p?.value,
                        disponivel: 'SIM',
                      });
                      setValue('descricao', produto?.descricao);
                      setValue('quantidade', 1);
                      setValue('unidade_medida_id', produto?.unidade_medida_id);
                      setValue('preco', produto?.preco);
                      setValue('referencia', produto?.referencia);
                      setAdditionals({
                        saldo: produto?.saldo,
                        referencia: produto?.referencia,
                        localizador: produto?.localizador,
                        imagem: produto?.imagem,
                      });
                    } catch (error) {
                    } finally {
                      setAdditionalsLoading(false);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input name="descricao" control={control} label="Descrição" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name="quantidade"
                control={control}
                label="Quantidade"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="unidade_medida_id"
                control={control}
                label="Unidade de Medida"
                options={drops?.UnidadeMedida}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask
                name="preco"
                control={control}
                label="Preço Unitário"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask
                name="desconto1_pc"
                control={control}
                label="% Desconto"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input name="referencia" control={control} label="Referência" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask
                name="przent"
                control={control}
                label="Prazo de Entrega"
                type="date"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div" align="center">
                Total:{' '}
                {getTotal()?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {Boolean(watch('produto_id')) && Boolean(additionals) && (
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  component="img"
                  sx={styles.image}
                  src={additionals?.imagem || Blank}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Saldo: {additionals?.saldo || '-'}{' '}
                  {additionals?.UnidadeMedida?.descricao || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" component="div" align="center">
                  Referência: {additionals?.referencia || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" component="div" align="center">
                  Localização: {additionals?.localizador || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Itens: [data] }))}
            loading={postLoading || additionalLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const options = [
    {
      name: 'Adicionar Item',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Item'),
    },
    {
      name: 'Cadastrar Produto',
      icon: 'add',
      action: () =>
        window.open(`https://cadastros.eprom2.com.br/app/Produtos/Manutencao`),
    },
    {
      name:
        documento?.DocumentoServico?.tipo === 'GARANTIA'
          ? 'Gerar Garantia'
          : 'Gerar Requisição de Compra',
      icon: 'post_add',
      action: () => {
        if (user?.nivel === 'ATENDENTE') {
          return toastWarning('Usuário sem permissão');
        }
        if (documento?.DocumentoServico?.tipo === 'GARANTIA') {
          const itens = selected?.map((m) => m?.id);
          if (!Boolean(itens?.length)) {
            return toastWarning('Selecione ao menos um item');
          }
          const hash = encryptURL({ id: documento?.id, itens });
          return navigate(`/app/Garantia/${hash}`);
        } else {
          const params = Boolean(selected?.length)
            ? `${documento?.id}/${JSON.stringify(selected?.map((m) => m?.id))}`
            : `${documento?.id}`;
          return window.open(
            `https://suprimentos.eprom2.com.br/app/Requisicoes/Gerar/${params}`
          );
        }
      },
    },
  ];

  return (
    <Card title="Itens" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined' }}
              options={options}
            />
          </Grid>
        )}
        {!!documento?.DocumentoItems?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={equals(selected, documento?.DocumentoItems)}
                        onChange={onSelectAll}
                      />
                    </TableCell>
                    <TableCell>Sequencia</TableCell>
                    <TableCell>Código do Produto</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell>Referência</TableCell>
                    <TableCell>Localização</TableCell>
                    {!disableActions && <TableCell>Saldo</TableCell>}
                    <TableCell>Quantidade</TableCell>
                    <TableCell>Preço</TableCell>
                    <TableCell>Desconto</TableCell>
                    <TableCell>Valor Total</TableCell>
                    <TableCell>Quantidade Requisitada</TableCell>
                    <TableCell>Documento de Compra</TableCell>
                    {!disableActions && (
                      <TableCell align="center">Ações</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documento?.DocumentoItems?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={selected?.includes(item)}
                          onChange={() => {
                            if (selected?.includes(item)) {
                              setSelected(selected?.filter((s) => item !== s));
                            } else {
                              setSelected([...selected, item]);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>{item?.sequencia}</TableCell>
                      <TableCell>{item?.produto_id}</TableCell>
                      <TableCell>{item?.descricao}</TableCell>
                      <TableCell>{item?.referencia}</TableCell>
                      <TableCell>{item?.Produto?.localizador}</TableCell>
                      {!disableActions && <TableCell>{item?.saldo}</TableCell>}
                      <TableCell>
                        {item?.quantidade} {item?.UnidadeMedida?.sigla}
                      </TableCell>
                      <TableCell>
                        {(item?.preco * 1)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>
                        {item?.desconto_vlr?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>
                        {(
                          item?.preco * item?.quantidade +
                          item?.desconto_vlr
                        )?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>
                        {summarizer(
                          item?.Destinos?.filter(
                            (d) =>
                              (d?.especie_id === 2 || d?.especie_id === 46) &&
                              (d?.natureza_operacao_id === 1 ||
                                d?.natureza_operacao_id === 54)
                          ),
                          'quantidade'
                        )}
                      </TableCell>
                      <TableCell>
                        {
                          item?.Destinos?.find(
                            (d) =>
                              (d?.especie_id === 2 || d?.especie_id === 46) &&
                              (d?.natureza_operacao_id === 1 ||
                                d?.natureza_operacao_id === 54) &&
                              Boolean(d?.ped_fornec)
                          )?.ped_fornec
                        }
                      </TableCell>
                      {!disableActions && (
                        <TableCell align="center">
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(<Modal item={item} />, 'Editar Item')
                            }
                          >
                            <Edit />
                          </IconButton>
                          {item?.sequencia !== 1 && (
                            <IconButton
                              size="small"
                              onClick={() => onDelete({ rotina, id: item?.id })}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Total:{' '}
            {getTotalDocumento()?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Itens;
