import { AuthProvider } from 'contexts/AuthContext';
import { NotificaçõesProvider } from 'contexts/NotificaçõesContext';
import { GridProvider } from 'contexts/GridContext';
import { DropsProvider } from 'contexts/DropsContext';
import { VendaProvider } from 'contexts/VendaContext';
import { OrdensProvider } from 'contexts/OrdensContext';
import { AgendaProvider } from 'contexts/AgendaContext';
import { MarcasProvider } from 'contexts/MarcasContext';
import { ModelosProvider } from 'contexts/ModelosContext';
import { EntidadesProvider } from 'contexts/EntidadesContext';
import { EquipamentosProvider } from 'contexts/EquipamentosContext';
import { RelatoriosProvider } from 'contexts/RelatoriosContext';
import { GraficosProvider } from 'contexts/GraficosContext';
import {
  DialogProvider,
  ModalProvider,
  DrawerProvider,
} from 'components/Modals';

const Providers = ({ children }) => (
  <AuthProvider>
    <NotificaçõesProvider>
      <OrdensProvider>
        <VendaProvider>
          <EntidadesProvider>
            <EquipamentosProvider>
              <AgendaProvider>
                <MarcasProvider>
                  <ModelosProvider>
                    <RelatoriosProvider>
                      <GraficosProvider>
                        <DropsProvider>
                          <GridProvider>
                            <ModalProvider>
                              <DialogProvider>
                                <DrawerProvider>{children}</DrawerProvider>
                              </DialogProvider>
                            </ModalProvider>
                          </GridProvider>
                        </DropsProvider>
                      </GraficosProvider>
                    </RelatoriosProvider>
                  </ModelosProvider>
                </MarcasProvider>
              </AgendaProvider>
            </EquipamentosProvider>
          </EntidadesProvider>
        </VendaProvider>
      </OrdensProvider>
    </NotificaçõesProvider>
  </AuthProvider>
);

export default Providers;
