import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { AuthContext } from 'contexts/AuthContext';
import { OrdensContext } from 'contexts/OrdensContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import VirtualDrop from 'components/VirtualDrop';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Equipamentos = ({
  drops,
  onSubmit,
  onDelete,
  documento = {},
  disableActions,
}) => {
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const rotina = 'DocumentoEquipamento';

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      sn: item?.sn || '',
      equipamento_id: item?.equipamento_id || null,
      relatocli: item?.relatocli || '',
      relatotec: item?.relatotec || '',
      acessorios: item?.acessorios || '',
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { user } = useContext(AuthContext);
    const { postLoading } = useContext(OrdensContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="sn"
            control={control}
            label="Serial Number"
            disabled={user?.nivel === 'TÉCNICO'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VirtualDrop
            name="equipamento_id"
            control={control}
            label="Equipamento"
            options={drops?.Equipamento}
            disabled={user?.nivel === 'TÉCNICO'}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="relatocli"
            control={control}
            label="Relato do Cliente"
            multiline
            rows={3}
            disabled={user?.nivel === 'TÉCNICO'}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="relatotec"
            control={control}
            label="Relato do Técnico"
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="acessorios"
            control={control}
            label="Observação / Acessórios"
            multiline
            rows={3}
            disabled={user?.nivel === 'TÉCNICO'}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Equipamentos: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Equipamentos" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Equipamento')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!documento?.DocumentoEquipamentos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Equipamento</TableCell>
                    <TableCell>Serial Number</TableCell>
                    <TableCell>Referência</TableCell>
                    <TableCell>Marca</TableCell>
                    <TableCell>Modelo</TableCell>
                    <TableCell>Alimentação</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documento?.DocumentoEquipamentos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.Equipamento?.descricao}</TableCell>
                      <TableCell>{item?.sn}</TableCell>
                      <TableCell>{item?.Equipamento?.referencia}</TableCell>
                      <TableCell>{item?.Equipamento?.Cemarca?.marca}</TableCell>
                      <TableCell>{item?.Equipamento?.Modelo?.modelo}</TableCell>
                      <TableCell>{item?.Equipamento?.alimentacao}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() =>
                            navigate('/app/Equipamento', {
                              state: { equipamento: item, disableActions },
                            })
                          }
                        >
                          <Visibility />
                        </IconButton>
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Equipamento'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Equipamentos;
