import { createContext, useContext, useState } from 'react';
import api from 'services/api';
import { AuthContext } from 'contexts/AuthContext';

const filter = {
  like: '',
  id: '',
  tipo: [],
  localizacao: '',
  documento: '',
  dtmodificacao: { i: null, f: null },
  dtaprovacao: { i: null, f: null },
  dtemissao: { i: null, f: null },
  cadastro_id: [],
  tecnico_id: [],
  przent: { i: null, f: null },
  status_id: [],
  equipamento: [],
  whatsapp: '',
};

export const OrdensContext = createContext();

export const OrdensProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [ordens, setOrdens] = useState({
    data: [],
    colunas: [],
    order:
      user?.nivel === 'TÉCNICO'
        ? { field: 'dtaprovacao', sort: 'desc' }
        : { field: 'id', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });
  const [allOrdens, setAllOrdens] = useState({
    data: [],
    colunas: [],
    order: { field: 'id', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });
  const [ordem, setOrdem] = useState(null);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const getOrdens = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: ordens?.filter,
        page: ordens?.page,
        size: ordens?.size,
        order: ordens?.order,
        ...payload,
      });
      setOrdens((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getAllOrdens = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: allOrdens?.filter,
        page: allOrdens?.page,
        size: allOrdens?.size,
        order: allOrdens?.order,
        ...payload,
      });
      setAllOrdens((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getOrdem = async (id, cb) => {
    try {
      setOrdem(null);
      setGetLoading(true);
      const { data } = await api.get(`/Cadastros/Documento/${id}`, {
        params: {
          Servico: true,
          Equipamentos: true,
          Itens: true,
          Defeitos: true,
          Registros: true,
          Entrada: true,
          Saida: true,
          Financeiro: true,
          Transportes: true,
          Moedas: true,
        },
      });
      setOrdem(data);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getOrdemResumo = async (id) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Cadastros/Documento/Resumo/${id}`);
      setOrdem(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postDocumento = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postDocumentos = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Multiple', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postDocumentoMidia = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Midia', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postAnexo = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Registro/Anexo', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteAnexo = async ({ id, cb }) => {
    try {
      setPostLoading(true);
      await api.delete(`/Cadastros/Documento/Registro/Anexo/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postFatura = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const { data: res } = await api.post('/Servicos/Ordens/Gerar', data);
      if (cb) {
        cb();
      }
      if ((res?.urls || [])?.length) {
        res?.urls?.map((url, i) =>
          setTimeout(() => window.open(url), 1000 * i)
        );
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const exportOrdens = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Servicos/Ordens/Exportar', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <OrdensContext.Provider
      value={{
        ordem,
        ordens,
        allOrdens,
        getLoading,
        postLoading,
        getOrdem,
        getOrdemResumo,
        getOrdens,
        postDocumento,
        postDocumentos,
        postDocumentoMidia,
        postAnexo,
        deleteAnexo,
        getAllOrdens,
        postFatura,
        exportOrdens,
      }}
    >
      {children}
    </OrdensContext.Provider>
  );
};
