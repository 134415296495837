import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { OrdensContext } from 'contexts/OrdensContext';
import { DropsContext } from 'contexts/DropsContext';
import { toastWarning } from 'utils/toast';
import { useDialog } from 'components/Modals';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const ProcessoModal = ({ callback, ordem }) => {
  const defaultValues = {
    documento_item_id: null,
    descricao: '',
    ccusto_id: null,
  };
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading } = useContext(OrdensContext);
  const { control, handleSubmit } = useForm({ defaultValues });
  const itens =
    ordem?.DocumentoItems?.filter(
      (f) =>
        !Boolean(f?.produto_id) ||
        Boolean(f?.Produto?.Celinha?.servico === 'SIM')
    ) || [];

  const onSubmit = (values) => {
    if (!Boolean(values?.documento_item_id)) {
      return toastWarning('Item obrigatório');
    }

    const selected = itens?.find((f) => f?.id === values?.documento_item_id);

    const operacao = Boolean(selected?.DocumentoItemProcessos?.length)
      ? selected?.DocumentoItemProcessos?.reduce((prev, current) => {
          return prev?.operacao > current?.operacao ? prev : current;
        })?.operacao + 10
      : 10;

    const data = {
      Itens: [
        {
          id: values?.documento_item_id,
          documento_id: ordem?.id,
          Processos: [{ ...values, operacao, apontamento: 'SIM' }],
        },
      ],
    };

    postDocumento({
      data,
      cb: () => {
        closeDialog();
        if (callback) {
          callback();
        }
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Informações Gerais
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          name="documento_item_id"
          control={control}
          label="Item"
          options={itens?.map((i) => ({ value: i?.id, label: i?.descricao }))}
        />
      </Grid>
      <Grid item xs={12}>
        <Input name="descricao" control={control} label="Descrição" />
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          name="ccusto_id"
          control={control}
          label="Centro de Custo"
          options={drops?.Ccusto}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProcessoModal;
