import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { AuthContext } from 'contexts/AuthContext';
import { toastWarning } from 'utils/toast';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Defeitos = ({
  drops,
  loading,
  onSubmit,
  onDelete,
  equipamento = {},
  disableActions,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'DocumentoEquipamentoDefeito';
  const { user } = useContext(AuthContext);

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      users_id: user?.id,
      documento_equipamento_id: equipamento?.id || null,
      defeito_id: item?.defeito_id || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });

    const onConfirm = (values) => {
      if (!Boolean(values?.defeito_id)) {
        toastWarning('Defeito obrigatório');
      } else {
        const Equipamentos = [
          {
            id: equipamento?.id,
            documento_id: equipamento?.documento_id,
            Defeitos: [values],
          },
        ];
        onSubmit({ Equipamentos });
      }
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            name="defeito_id"
            control={control}
            label="Defeito"
            options={drops?.Defeito}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={loading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Defeitos" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Defeito')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!equipamento?.DocumentoEquipamentoDefeitos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Defeito</TableCell>
                    {!disableActions && (
                      <TableCell align="center">Ações</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {equipamento?.DocumentoEquipamentoDefeitos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.Defeito?.descricao}</TableCell>
                      {!disableActions && (
                        <TableCell align="center">
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Defeito'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Defeitos;
