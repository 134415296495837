const styles = {
  container: {
    marginTop: '.25em',
    marginBottom: '.25em',
  },
  card: {
    flex: 1,
  },
};

export default styles;
