import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Box, Grid } from '@mui/material';
import { AuthContext } from 'contexts/AuthContext';
import { GraficosContext } from 'contexts/GraficosContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Loader from 'components/Loader';
import { BarChart } from '@mui/x-charts/BarChart';

const App = () => {
  const { getGraficos, loading, graficos } = useContext(GraficosContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    getGraficos({
      paths: [
        'ServicosPendentes',
        'ServicosAtendimentosPendentes',
        'ServicosTecnicosPendentes',
      ],
    });
  }, []);

  const ServicosPendentes = ({ data }) => {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const enabledUsers = ['SUPERVISÃO', 'GERÊNCIA', 'DIRETORIA', 'LIDER'];

    useLayoutEffect(() => {
      if (ref?.current?.offsetWidth) {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetWidth / 2);
      }
    }, []);

    if (!enabledUsers.includes(user?.nivel)) {
      return null;
    }

    return (
      <Grid item xs={12} sm={6}>
        <Card title="Ordens de Serviço Pendentes">
          <Box ref={ref}>
            <BarChart
              xAxis={[
                {
                  id: 'osPendentes',
                  data: ['Pendentes', 'Atendimento', 'Técnico'],
                  scaleType: 'band',
                },
              ]}
              series={[
                {
                  color: '#22267B',
                  data: [
                    data?.count || 0,
                    data?.rows[0]?.atendimento || 0,
                    data?.rows[0]?.tecnico || 0,
                  ],
                },
              ]}
              width={width}
              height={height}
            />
          </Box>
        </Card>
      </Grid>
    );
  };

  const ServicosAtendimentosPendentes = ({ data }) => {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const enabledUsers = [
      'SUPERVISÃO',
      'GERÊNCIA',
      'DIRETORIA',
      'LIDER',
      'ATENDENTE',
    ];

    useLayoutEffect(() => {
      if (ref?.current?.offsetWidth) {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetWidth / 2);
      }
    }, []);

    if (!enabledUsers.includes(user?.nivel)) {
      return null;
    }

    return (
      <Grid item xs={12} sm={6}>
        <Card title="Atendimentos Pendentes">
          <Box ref={ref}>
            <BarChart
              xAxis={[
                {
                  id: 'atendimentosPendentes',
                  data: [
                    'Novo Serviço',
                    'Orç. Enviado',
                    'Fat. Liberado',
                    'Agendado',
                    'Orç. à Enviar',
                    'Equip Disp.',
                    'Peça Indisp.',
                  ],
                  scaleType: 'band',
                },
              ]}
              series={[
                {
                  color: '#22267B',
                  data: [
                    data?.rows[0]?.novas || 0,
                    data?.rows[0]?.orcenviado || 0,
                    data?.rows[0]?.fatlib || 0,
                    data?.rows[0]?.agendado || 0,
                    data?.rows[0]?.orcenviar || 0,
                    data?.rows[0]?.equipdisp || 0,
                    data?.rows[0]?.pecindisp || 0,
                  ],
                },
              ]}
              width={width}
              height={height}
            />
          </Box>
        </Card>
      </Grid>
    );
  };

  const ServicosTecnicosPendentes = ({ data }) => {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const enabledUsers = [
      'SUPERVISÃO',
      'GERÊNCIA',
      'DIRETORIA',
      'LIDER',
      'TÉCNICO',
    ];

    useLayoutEffect(() => {
      if (ref?.current?.offsetWidth) {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetWidth / 2);
      }
    }, []);

    if (!enabledUsers.includes(user?.nivel)) {
      return null;
    }

    return (
      <Grid item xs={12} sm={6}>
        <Card title="Serviços Técnicos Pendentes">
          <Box ref={ref}>
            <BarChart
              xAxis={[
                {
                  id: 'tecnicosPendentes',
                  data: ['Orç. Aprovado', 'Aguardando Peça', 'Emitir Orç.'],
                  scaleType: 'band',
                },
              ]}
              series={[
                {
                  color: '#22267B',
                  data: [
                    data?.rows[0]?.orcaprov || 0,
                    data?.rows[0]?.agpeca || 0,
                    data?.rows[0]?.envorc || 0,
                  ],
                },
              ]}
              width={width}
              height={height}
            />
          </Box>
        </Card>
      </Grid>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header />
      <Grid container spacing={2}>
        <ServicosPendentes data={graficos?.ServicosPendentes} />
        <ServicosAtendimentosPendentes
          data={graficos?.ServicosAtendimentosPendentes}
        />
        <ServicosTecnicosPendentes data={graficos?.ServicosTecnicosPendentes} />
      </Grid>
    </Container>
  );
};

export default App;
