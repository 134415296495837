import React, { useContext, useEffect, useState } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Grid,
} from '@mui/material';
import {
  ExpandMore,
  Stop,
  PlayArrow,
  FiberManualRecord,
  WorkHistoryOutlined,
  CalendarMonth,
  Delete,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrdensContext } from 'contexts/OrdensContext';
import { AuthContext } from 'contexts/AuthContext';
import { GridContext } from 'contexts/GridContext';
import { useDialog, useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import ProcessoModal from 'components/Modals/ProcessoModal';
import ApontamentoModal from 'components/Modals/ApontamentoModal';
import AgendamentoModal from 'components/Modals/AgendamentoModal';
import Container from 'components/Container';
import Button from 'components/Button';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import moment from 'moment';

const Apontamento = () => {
  const titulo = 'Apontamento de Horas';
  const navigate = useNavigate();
  const { state } = useLocation();
  const { openDialog, closeDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { user } = useContext(AuthContext);
  const { deleteGrid } = useContext(GridContext);
  const { ordem, getLoading, getOrdem, postDocumento, postLoading } =
    useContext(OrdensContext);

  useEffect(() => {
    if (state?.documento) {
      getOrdem(state?.documento?.id);
    } else {
      navigate(-1);
    }
  }, [state]);

  const onSubmitApontamento = (processo) => {
    const iniciado = processo?.DocumentoItemProcessoAponts?.find((f) =>
      Boolean(f?.inicio && !f?.fim)
    );
    const Apontamentos = [
      {
        id: iniciado?.id,
        documento_item_processo_id: processo?.id,
        executante_id: user?.id,
        inicio: iniciado?.inicio || moment().format('YYYY-MM-DD HH:mm:ss'),
        fim: iniciado?.inicio ? moment().format('YYYY-MM-DD HH:mm:ss') : null,
        users_id: user?.id,
      },
    ];
    const data = {
      Itens: [
        {
          id: processo?.documento_item_id,
          documento_id: state?.documento?.id,
          Processos: [{ id: processo?.id, Apontamentos }],
        },
      ],
    };
    postDocumento({ data, cb: () => getOrdem(state?.documento?.id) });
  };

  const onEditApontamento = (apontamentos = [], processo) => {
    const Apontamentos = apontamentos?.map((a) => ({
      id: a?.id,
      users_id: a?.users_id,
      executante_id: a?.executante_id,
      documento_item_processo_id: a?.documento_item_processo_id,
      inicio: moment(a?.inicio, 'DD/MM/YYYY [às] HH:mm').isValid()
        ? moment(a?.inicio, 'DD/MM/YYYY [às] HH:mm').format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : undefined,
      fim: moment(a?.fim, 'DD/MM/YYYY [às] HH:mm').isValid()
        ? moment(a?.fim, 'DD/MM/YYYY [às] HH:mm').format('YYYY-MM-DD HH:mm:ss')
        : undefined,
    }));

    const data = {
      Itens: [
        {
          id: processo?.documento_item_id,
          documento_id: state?.documento?.id,
          Processos: [{ id: processo?.id, Apontamentos }],
        },
      ],
    };
    postDocumento({
      data,
      cb: () => {
        closeDialog();
        getOrdem(state?.documento?.id);
      },
    });
  };

  const onDeleteApontamento = (data) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            ...data,
            cb: () => {
              getOrdem(state?.documento?.id);
              closeModal();
              closeDialog();
            },
          })
        }
      />
    );

  const onDeleteProcesso = (id) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            id,
            rotina: 'DocumentoItemProcesso',
            cb: () => {
              getOrdem(state?.documento?.id);
              closeModal();
            },
          })
        }
      />
    );

  const RenderItems = ({ items = [] }) => {
    return items?.map((i, idx) => {
      const [expanded, setExpanded] = useState(true);
      return (
        <Accordion
          key={idx?.toString()}
          expanded={expanded}
          onChange={(_, v) => setExpanded(v)}
          elevation={3}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="button">{i?.descricao}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <RenderProcessos processos={i?.DocumentoItemProcessos} />
            </List>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const RenderProcessos = ({ processos = [] }) =>
    processos
      ?.filter((f) => f?.apontamento === 'SIM')
      ?.map((p, i) => {
        const iniciado = p?.DocumentoItemProcessoAponts?.find((f) =>
          Boolean(f?.inicio && !f?.fim)
        );
        return (
          <ListItem key={i?.toString()}>
            <ListItemText primary={p?.descricao} />
            <IconButton size="small" disabled>
              <FiberManualRecord sx={{ color: iniciado ? 'green' : 'red' }} />
            </IconButton>
            <IconButton size="small" onClick={() => onSubmitApontamento(p)}>
              {postLoading ? (
                <CircularProgress size={20} />
              ) : iniciado ? (
                <Stop />
              ) : (
                <PlayArrow />
              )}
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                openDialog(
                  <ApontamentoModal
                    processo={p}
                    onDelete={(dt) => onDeleteApontamento(dt)}
                    onEdit={onEditApontamento}
                  />,
                  'Apontamentos Realizados'
                )
              }
            >
              <WorkHistoryOutlined />
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                openDialog(
                  <AgendamentoModal
                    processo={p}
                    documento_id={state?.documento?.id}
                  />,
                  'Agendar Tarefa'
                )
              }
            >
              <CalendarMonth />
            </IconButton>
            <IconButton size="small" onClick={() => onDeleteProcesso(p?.id)}>
              <Delete />
            </IconButton>
          </ListItem>
        );
      });

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                openDialog(
                  <ProcessoModal
                    ordem={ordem}
                    callback={() => getOrdem(state?.documento?.id)}
                  />,
                  'Adicionar Processo'
                );
              }}
            >
              Adicionar Processo
            </Button>
          </Grid>
          <Grid item xs={12}>
            <RenderItems
              items={ordem?.DocumentoItems?.filter((f) =>
                Boolean(f?.DocumentoItemProcessos?.length)
              )}
            />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Apontamento;
