import React, { useContext, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Grid, Icon } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { OrdensContext } from 'contexts/OrdensContext';
import { toastWarning } from 'utils/toast';
import { useDialog } from 'components/Modals';
import VirtualDrop from 'components/VirtualDrop';
import Button from 'components/Button';
import InputMask from 'components/InputMask';
import moment from 'moment';

const PeçasEnvioModal = ({ agenda, callback }) => {
  const [produtos, setProdutos] = useState([]);
  const defaultValues = {
    produto_id: null,
    quantidade: '',
  };
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { user } = useContext(AuthContext);
  const { postDocumento, postLoading } = useContext(OrdensContext);
  const { control, handleSubmit, resetField } = useForm({ defaultValues });

  const onSubmit = () => {
    if (!Boolean(produtos?.length)) {
      toastWarning('Inserir no mínimo um produto');
    } else {
      const data = {
        Documento: {
          especie_id: 38,
          natureza_operacao_id: 58,
          filial_id: user?.filial_id,
          cadastro_id: user?.entidade_id,
          dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
          users_id: user?.id,
        },
        Itens: produtos?.map((p, i) => ({
          sequencia: i + 1,
          produto_id: p?.id,
          quantidade: p?.quantidade,
          Origens: [
            {
              documento_item_org_id:
                agenda?.DocumentoItemProcesso?.documento_item_id,
            },
          ],
        })),
      };
      postDocumento({
        data,
        cb: () => {
          closeDialog();
          if (callback) {
            callback();
          }
        },
      });
    }
  };

  const onAdd = (values) => {
    if (!Boolean(values?.produto_id)) {
      toastWarning('Produto não informado');
    } else if (!Boolean(values?.quantidade)) {
      toastWarning('Quantidade não informada');
    } else {
      const produto = drops?.Produto?.find(
        (p) => p?.value === values?.produto_id
      );
      setProdutos((prev) => [
        ...prev,
        {
          id: produto?.value,
          nome: produto?.label,
          quantidade: values?.quantidade,
        },
      ]);
      resetField('produto_id');
      resetField('quantidade');
    }
  };

  const onCellEditCommit = (e) => {
    const editedRows = produtos?.map((c) => {
      if (c?.id === e?.id) {
        return { ...c, [e?.field]: e?.value };
      } else {
        return { ...c };
      }
    });
    setProdutos(editedRows);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <VirtualDrop
          name="produto_id"
          control={control}
          label="Produto"
          options={drops?.Produto}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask name="quantidade" control={control} label="Quantidade" />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit(onAdd)}
        >
          Adicionar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={produtos}
          columns={[
            {
              field: 'nome',
              headerName: 'Nome',
              flex: 5,
              sortable: false,
            },
            {
              field: 'quantidade',
              headerName: 'Quantidade',
              type: 'number',
              flex: 2,
              editable: true,
              sortable: false,
            },
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              flex: 1,
              getActions: (params) => [
                <GridActionsCellItem
                  icon={<Icon>delete</Icon>}
                  label="Excluir"
                  onClick={() =>
                    setProdutos(produtos?.filter((p) => p?.id !== params?.id))
                  }
                />,
              ],
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
          onCellEditCommit={onCellEditCommit}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default PeçasEnvioModal;
