import React, { useContext, useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Grid, Icon } from '@mui/material';
import { AuthContext } from 'contexts/AuthContext';
import { OrdensContext } from 'contexts/OrdensContext';
import { useDialog } from 'components/Modals';
import Button from 'components/Button';
import moment from 'moment';

const PeçasRetornoModal = ({ agenda, callback }) => {
  const [itens, setItens] = useState([]);
  const { closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { postDocumento, postLoading } = useContext(OrdensContext);

  useEffect(() => {
    setInitialItens();
  }, []);

  const setInitialItens = () => {
    setItens([]);
    agenda?.DocumentoItemProcesso?.DocumentoItem?.DocumentoItemOrigems?.map(
      (a) => {
        if (Boolean(a?.DocumentoItem?.qtd_total)) {
          setItens((prev) => [
            ...prev,
            {
              id: a?.documento_item_id,
              produto_id: a?.DocumentoItem?.produto_id,
              dtemissao: a?.DocumentoItem?.dtemissao
                ? moment(a?.DocumentoItem?.dtemissao).format(
                    'DD/MM/YYYY [às] HH:mm'
                  )
                : null,
              nome: a?.DocumentoItem?.produto,
              quantidade: a?.DocumentoItem?.qtd_total,
              quantidade_max: a?.DocumentoItem?.qtd_total,
            },
          ]);
        }
      }
    );
  };

  const onSubmit = () => {
    const data = {
      Documento: {
        especie_id: 38,
        natureza_operacao_id: 60,
        filial_id: user?.filial_id,
        cadastro_id: user?.entidade_id,
        dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
        users_id: user?.id,
      },
      Itens: itens?.map((i, idx) => ({
        sequencia: idx + 1,
        produto_id: i?.produto_id,
        quantidade: i?.quantidade,
        Vinculos: [{ documento_item_id_vinculo: i?.id }],
      })),
    };
    postDocumento({
      data,
      cb: () => {
        closeDialog();
        if (callback) {
          callback();
        }
      },
    });
  };

  const onCellEditCommit = (e) => {
    const editedRows = itens?.map((c) => {
      if (c?.id === e?.id) {
        if (e?.field === 'quantidade' && e?.value > c?.quantidade_max) {
          return { ...c, [e?.field]: c?.quantidade_max };
        } else {
          return { ...c, [e?.field]: e?.value };
        }
      } else {
        return { ...c };
      }
    });
    setItens(editedRows);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign="center">
        <Button variant="outlined" color="primary" onClick={setInitialItens}>
          Redefinir
        </Button>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={itens}
          columns={[
            {
              field: 'nome',
              headerName: 'Nome',
              flex: 5,
              sortable: false,
            },
            {
              field: 'dtemissao',
              headerName: 'Data da Remessa',
              flex: 2,
              editable: true,
              sortable: false,
            },
            {
              field: 'quantidade',
              headerName: 'Quantidade',
              type: 'number',
              flex: 2,
              editable: true,
              sortable: false,
            },
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              flex: 1,
              getActions: (params) => [
                <GridActionsCellItem
                  icon={<Icon>delete</Icon>}
                  label="Excluir"
                  onClick={() =>
                    setItens(itens?.filter((p) => p?.id !== params?.id))
                  }
                />,
              ],
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
          onCellEditCommit={onCellEditCommit}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default PeçasRetornoModal;
