import React from 'react';
import Loader from 'components/Loader';
import Documento from './Documento';
import Serviço from './Serviço';
import Equipamentos from './Equipamentos';
import Itens from './Itens';
import Registros from './Registros';

const Tabs = ({ tab, getLoading, ...props }) => {
  if (getLoading) {
    return <Loader />;
  }
  switch (tab) {
    case 0:
      return <Documento {...props} />;
    case 1:
      return <Serviço {...props} />;
    case 2:
      return <Equipamentos {...props} />;
    case 3:
      return <Itens {...props} />;
    case 4:
      return <Registros {...props} />;

    default:
      return null;
  }
};

export default Tabs;
