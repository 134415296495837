import React, { useContext, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Add } from '@mui/icons-material';
import { EquipamentosContext } from 'contexts/EquipamentosContext';
import { ModelosContext } from 'contexts/ModelosContext';
import { MarcasContext } from 'contexts/MarcasContext';
import { DropsContext } from 'contexts/DropsContext';
import { dropBoolean, dropAlimentacao } from 'utils/drops';
import { useDialog } from 'components/Modals';
import VirtualDrop from 'components/VirtualDrop';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const FullEquipamentoModal = ({ equipamento, callback }) => {
  const [tab, setTab] = useState(0);
  const defaultValues = {
    descricao: equipamento?.descricao || '',
    referencia: equipamento?.referencia || '',
    patrimonio: equipamento?.patrimonio || '',
    alimentacao: equipamento?.alimentacao || null,
    cemarca_id: equipamento?.cemarca_id || null,
    modelo_id: equipamento?.modelo_id || null,
  };
  const { closeDialog } = useDialog();
  const { drops, getDrops } = useContext(DropsContext);
  const { control, handleSubmit, setValue, watch } = useForm({ defaultValues });

  const Marca = ({ mainSetValue, mainControl }) => {
    const [visible, setVisible] = useState(false);
    const { postLoading, postMarca } = useContext(MarcasContext);
    const defaultValues = { marca: '', ativo: null };
    const { control, handleSubmit } = useForm({
      defaultValues,
    });

    const onSubmit = (data) =>
      postMarca({
        data,
        cb: ({ id }) => {
          mainSetValue('cemarca_id', id);
          getDrops(['Marca']);
          setVisible(false);
        },
      });

    if (visible) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" align="center">
              Cadastrar Marca
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="marca" control={control} label="Marca" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="ativo"
              control={control}
              label="Ativo"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: '.5em' }}
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ mx: '.5em' }}
              onClick={() => setVisible((prev) => !prev)}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" align="center">
              Selecionar Marca
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <VirtualDrop
              name="cemarca_id"
              control={mainControl}
              label="Marca"
              options={drops?.Marca}
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              onClick={() => setVisible((prev) => !prev)}
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={6} textAlign="start">
            <Button
              variant="outlined"
              color="primary"
              disabled={tab < 1}
              onClick={() => setTab((prev) => prev - 1)}
            >
              Anterior
            </Button>
          </Grid>
          <Grid item xs={6} textAlign="end">
            <Button
              variant="outlined"
              color="primary"
              disabled={tab > 1}
              onClick={() => setTab((prev) => prev + 1)}
            >
              Próximo
            </Button>
          </Grid>
        </Grid>
      );
    }
  };
  const Modelo = ({ mainSetValue, mainControl, mainWatch }) => {
    const [visible, setVisible] = useState(false);
    const { postLoading, postModelo } = useContext(ModelosContext);
    const defaultValues = {
      modelo: '',
      anofab: '',
      cemarca_id: mainWatch('cemarca_id') || null,
      ativo: null,
    };
    const { control, handleSubmit } = useForm({
      defaultValues,
    });
    const onSubmit = (data) =>
      postModelo({
        data,
        cb: ({ id }) => {
          mainSetValue('modelo_id', id);
          getDrops(['Modelo']);
          setVisible(false);
        },
      });

    if (visible) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" align="center">
              Cadastrar Modelo
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="cemarca_id"
              control={control}
              label="Marca"
              options={drops?.Marca}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="modelo" control={control} label="Modelo" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="anofab"
              control={control}
              label="Ano de Fabricação"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="ativo"
              control={control}
              label="Ativo"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: '.5em' }}
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ mx: '.5em' }}
              onClick={() => setVisible((prev) => !prev)}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" align="center">
              Selecionar Modelo
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <VirtualDrop
              name="modelo_id"
              control={mainControl}
              label="Modelo"
              options={drops?.Modelo?.filter(
                (m) => m?.cemarca_id === mainWatch('cemarca_id')
              )}
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              onClick={() => setVisible((prev) => !prev)}
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={6} textAlign="start">
            <Button
              variant="outlined"
              color="primary"
              disabled={tab < 1}
              onClick={() => setTab((prev) => prev - 1)}
            >
              Anterior
            </Button>
          </Grid>
          <Grid item xs={6} textAlign="end">
            <Button
              variant="outlined"
              color="primary"
              disabled={tab > 1}
              onClick={() => setTab((prev) => prev + 1)}
            >
              Próximo
            </Button>
          </Grid>
        </Grid>
      );
    }
  };
  const Equipamento = () => {
    const { postEquipamento, postLoading } = useContext(EquipamentosContext);
    const onSubmit = (values) => {
      const data = { ...values, id: equipamento?.id };
      postEquipamento({
        data,
        cb: ({ id }) => {
          if (callback) {
            callback(id);
          }
          closeDialog();
          getDrops(['Equipamento']);
        },
      });
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações do Equipamento
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="referencia" control={control} label="Referência" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="patrimonio" control={control} label="Patrimônio" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="alimentacao"
            control={control}
            label="Alimentação"
            options={dropAlimentacao}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="cemarca_id"
            control={control}
            label="Marca"
            options={drops?.Marca}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VirtualDrop
            name="modelo_id"
            control={control}
            label="Modelo"
            options={drops?.Modelo}
            disabled
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  if (tab === 0) {
    return <Marca mainSetValue={setValue} mainControl={control} />;
  }
  if (tab === 1) {
    return (
      <Modelo mainSetValue={setValue} mainControl={control} mainWatch={watch} />
    );
  }
  if (tab === 2) {
    return <Equipamento />;
  }
  return null;
};

export default FullEquipamentoModal;
