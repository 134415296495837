import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import App from 'views/App';
import Ordens from 'views/Ordens';
import Ordem from 'views/Ordem';
import OrdemResumo from 'views/OrdemResumo';
import OrdemServiço from 'views/OrdemServiço';
import OrdemFaturamento from 'views/OrdemFaturamento';
import OrdemEquipamento from 'views/OrdemEquipamento';
import OrdemEquipamentos from 'views/OrdemEquipamentos';
import Agenda from 'views/Agenda';
import Técnico from 'views/Técnico';
// import Produtos from 'views/Produtos';
import Equipamento from 'views/Equipamento';
import Equipamentos from 'views/Equipamentos';
import Apontamento from 'views/Apontamento';
import Garantia from 'views/Garantia';
import Configurações from 'views/Configurações';
import Relatórios from 'views/Relatórios';
import RelatóriosFiltros from 'views/Relatórios/Filtros';
import User from 'views/User';
//OUTLETS
import OutletDocumento from './Outlets/Documento';

const RoutesTree = () => (
  <Routes>
    <Route path="" element={<App />} />
    <Route path="Relatorios" element={<Outlet />}>
      <Route path="" element={<Relatórios />} />
      <Route path="Filtros" element={<RelatóriosFiltros />} />
    </Route>
    <Route path="Ordens" element={<Ordens />} />
    <Route path="Ordem" element={<OutletDocumento />}>
      <Route path=":id" element={<Ordem />} />
      <Route path="Resumo" element={<OrdemResumo />} />
      <Route path="Servico" element={<OrdemServiço />} />
      <Route path="Faturamento/:hash" element={<OrdemFaturamento />} />
      <Route path="Equipamento" element={<OrdemEquipamento />} />
      <Route path="Equipamentos" element={<OrdemEquipamentos />} />
    </Route>
    <Route path="Agenda" element={<Agenda />} />
    <Route path="Tecnico" element={<Técnico />} />
    {/* <Route path="Produtos" element={<Produtos />} /> */}
    <Route path="Equipamento" element={<Equipamento />} />
    <Route path="Equipamentos" element={<Equipamentos />} />
    <Route path="Apontamento" element={<Apontamento />} />
    <Route path="Garantia/:hash" element={<Garantia />} />
    <Route path="Configuracoes" element={<Configurações />} />
    <Route path="User" element={<User />} />
  </Routes>
);

export default RoutesTree;
