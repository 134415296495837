import React from 'react';
import { Grid } from '@mui/material';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import { dropTipoServico, dropStatus } from 'utils/drops';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('status_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="status_id"
            control={control}
            label="Status"
            options={dropStatus}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('dtmodificacao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtmodificacao.i"
            control={control}
            label="Última Modificação Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtmodificacao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtmodificacao.f"
            control={control}
            label="Última Modificação Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtaprovacao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtaprovacao.i"
            control={control}
            label="Data de Aprovação Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtaprovacao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtaprovacao.f"
            control={control}
            label="Data de Aprovação Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.i"
            control={control}
            label="Data de Emissão Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.f"
            control={control}
            label="Data de Emissão Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('cadastro_id') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="cadastro_id"
            control={control}
            label="Entidade"
            options={drops?.Entidade}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('localizacao') && (
        <Grid item xs={12}>
          <Input name="localizacao" control={control} label="Localização" />
        </Grid>
      )}
      {checkDisplay('przent') && (
        <Grid item xs={6}>
          <InputMask
            name="przent.i"
            control={control}
            label="Prazo de Entrega Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('przent') && (
        <Grid item xs={6}>
          <InputMask
            name="przent.f"
            control={control}
            label="Prazo de Entrega Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={12}>
          <Input
            name="documento"
            control={control}
            label="Nº do Documento"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('tipo') && (
        <Grid item xs={12}>
          <Dropdown
            name="tipo"
            control={control}
            label="Tipo do Serviço"
            options={dropTipoServico}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('tecnico_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="tecnico_id"
            control={control}
            label="Técnico"
            options={drops?.Users}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('atendente_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="atendente_id"
            control={control}
            label="Atendente"
            options={drops?.Users}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('equipamento') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="equipamento"
            control={control}
            label="Equipamento"
            options={drops?.Equipamento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('whatsapp') && (
        <Grid item xs={12}>
          <Input name="whatsapp" control={control} label="Contato" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
