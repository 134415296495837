import CryptoJS from 'crypto-js';
import moment from 'moment';
import api from 'services/api';

export const findOnArray = (value, arr = [], field) => {
  const rec = arr.find((i) => i.value === value);
  if (field) {
    return rec?.[field] || null;
  }
  return rec || null;
};

export const formatDocument = (document = '') => {
  let parseDoc = document.replace(/([\/\.\-])/g, '');
  if (parseDoc.length === 14) {
    parseDoc = `${parseDoc.substring(0, 2)}.${parseDoc.substring(
      2,
      5
    )}.${parseDoc.substring(5, 8)}/${parseDoc.substring(
      8,
      12
    )}-${parseDoc.substring(12, 14)}`;
  }
  if (parseDoc.length === 11) {
    parseDoc = `${parseDoc.substring(0, 3)}.${parseDoc.substring(
      3,
      6
    )}.${parseDoc.substring(6, 9)}-${parseDoc.substring(9, 11)}`;
  }

  return parseDoc;
};

export const summarizer = (arr = [], field) =>
  arr?.length &&
  Number(
    arr
      ?.map((i) => i[field])
      ?.reduce((prev, next) => prev + next)
      ?.toFixed(2)
  );

export const checkSaldoDocumento = async ({
  documento_id,
  user,
  dropNatureza = [],
}) => {
  if (Boolean(documento_id)) {
    const { data } = await api.get(`/Cadastros/Documento/${documento_id}`, {
      params: {
        Servico: true,
        Equipamentos: true,
        Itens: true,
        Defeitos: true,
        Registros: true,
        Entrada: true,
        Saida: true,
        Financeiro: true,
        Transportes: true,
        Moedas: true,
      },
    });
    const verifySaldo = data?.DocumentoItems?.some(
      (i) => i?.Cfo?.servico !== 'SIM' && (i?.quantidade || 0) > (i?.saldo || 0)
    );
    if (verifySaldo && Boolean(data?.DocumentoEquipamentos?.length)) {
      let form = {
        Documento: {
          especie_id: 2,
          natureza_operacao_id: 1,
          filial_id: data?.filial_id,
          cadastro_id: data?.cadastro_id,
          dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
          celocal_id: data?.celocal_id,
          contribuinte_id: data?.contribuinte_id,
          usofinal: data?.usofinal,
          users_id: user?.id,
        },
        RequisicaoCompra: { users_id: user?.id },
        Origens: [
          {
            documento_id,
            status_id: 21,
            datahora: moment().format('YYYY-MM-DD HH:mm:ss'),
          },
        ],
      };
      if (Boolean(data?.DocumentoEntrada)) {
        form = {
          ...form,
          Entrada: {
            serie: data?.DocumentoEntrada?.serie,
            dtentrada: data?.DocumentoEntrada?.dtentrada,
            custo: data?.DocumentoEntrada?.custo,
            ped_fornec: data?.DocumentoEntrada?.ped_fornec,
          },
        };
      }
      if (Boolean(data?.DocumentoSaida)) {
        form = {
          ...form,
          Saida: {
            serie_id: data?.DocumentoSaida?.serie_id,
            condicao_pagamento_id: data?.DocumentoSaida?.condicao_pagamento_id,
            entidade_endereco_id: data?.DocumentoSaida?.entidade_endereco_id,
            vendedor_id: data?.DocumentoSaida?.vendedor_id,
            ped_cliente: data?.DocumentoSaida?.ped_cliente,
            documento: data?.DocumentoSaida?.documento,
            dtatend: data?.DocumentoSaida?.dtatend,
            validade: data?.DocumentoSaida?.validade,
            dtsaida: data?.DocumentoSaida?.dtsaida,
            desc1: data?.DocumentoSaida?.desc1,
            desc2: data?.DocumentoSaida?.desc2,
            desc3: data?.DocumentoSaida?.desc3,
            desc4: data?.DocumentoSaida?.desc4,
            desc5: data?.DocumentoSaida?.desc5,
            forma_envio_id: data?.DocumentoSaida?.forma_envio_id,
            freteenv: data?.DocumentoSaida?.freteenv,
            fretecob: data?.DocumentoSaida?.fretecob,
            freterec: data?.DocumentoSaida?.freterec,
          },
        };
      }
      if (
        Boolean(data?.DocumentoServico) &&
        findOnArray(2, dropNatureza, 'label') === 'SERVICOS'
      ) {
        data = {
          ...data,
          Servico: {
            garantia: data?.DocumentoServico?.garantia,
            assunto: data?.DocumentoServico?.assunto,
            defeito: data?.DocumentoServico?.defeito,
            solicitacao: data?.DocumentoServico?.solicitacao,
            mail_contato: data?.DocumentoServico?.mail_contato,
          },
        };
      }
      if (Boolean(data?.DocumentoMoeda)) {
        form = {
          ...form,
          Moeda: {
            moeda_id: data?.DocumentoMoeda?.moeda_id,
            cotacao: data?.DocumentoMoeda?.cotacao,
          },
        };
      }
      if (Boolean(data?.DocumentoFinanceiros?.length)) {
        form = {
          ...form,
          Financeiros: data?.DocumentoFinanceiros?.map((f) => ({
            forma_pagto_id: f?.forma_pagto_id,
            parcela: f?.parcela,
            dtvenc: f?.dtvenc,
            valor: f?.valor,
            observacao_id: f?.observacao_id,
            observacao: f?.observacao,
          })),
        };
      }
      if (Boolean(data?.DocumentoTransportes?.length)) {
        form = {
          ...form,
          Transportes: data?.DocumentoTransportes?.map((t) => ({
            entidade_id: t?.entidade_id,
            tipotransp: t?.tipotransp,
            viatransp: t?.viatransp,
            fretepc: t?.fretepc,
            natureza: t?.natureza,
            locentrega: t?.locentrega,
            volume: t?.volume,
            tara: t?.tara,
            pesoliq: t?.pesoliq,
            pesobrt: t?.pesobrt,
            quantidade: t?.quantidade,
            especie: t?.especie,
            marca: t?.marca,
            numero: t?.numero,
            frota_id: t?.frota_id,
            motorista_id: t?.motorista_id,
          })),
        };
      }
      if (Boolean(data?.DocumentoItems?.length)) {
        form = {
          ...form,
          Itens: data?.DocumentoItems?.filter(
            (f) =>
              f?.Cfo?.servico !== 'SIM' &&
              (f?.quantidade || 0) > (f?.saldo || 0)
          )?.map((r, i) => {
            const Estruturas = r?.DocumentoItemEstruturas?.filter((f) =>
              Boolean(f?.produto_id)
            )?.map((e) => ({
              componente_id: e?.componente_id,
              operacao: e?.operacao,
              sequencia: e?.sequencia,
              quantidade: e?.quantidade,
              perda: e?.perda,
              volume: e?.volume,
              retornavel: e?.retornavel,
            }));
            const Processos = r?.DocumentoItemProcessos?.filter((f) =>
              Boolean(f?.produto_id)
            )?.map((p) => ({
              operacao: p?.operacao,
              descricao: p?.descricao,
              ccusto_id: p?.ccusto_id,
              tpreparacao: p?.tpreparacao,
              texecucao: p?.texecucao,
              apontamento: p?.apontamento,
              analisa_id: p?.analisa_id,
              aprova_id: p?.aprova_id,
            }));
            const Vinculos = [];

            let item = {
              sequencia: i + 1,
              cfo_id: r?.cfo_id,
              cfiscal_id: r?.cfiscal_id,
              produto_id: r?.produto_id,
              descricao: r?.descricao,
              quantidade: r?.quantidade - r?.saldo,
              unidade_medida_id: r?.unidade_medida_id,
              precobase_id: r?.precobase_id,
              preco: r?.preco,
              comissao_id: r?.comissao_id,
              comissao_pc: r?.comissao_pc,
              desconto_vlr: r?.desconto_vlr,
              desconto1_pc: r?.desconto1_pc,
              desconto2_pc: r?.desconto2_pc,
              desconto3_pc: r?.desconto3_pc,
              desconto4_pc: r?.desconto4_pc,
              desconto5_pc: r?.desconto5_pc,
              frete: r?.frete,
              seguro: r?.seguro,
              despac: r?.despac,
              cstfin: r?.cstfin,
              eventuais: r?.eventuais,
              outros: r?.outros,
              peso: r?.peso,
              przent: r?.przent,
              infadc: r?.infadc,
              referencia: r?.referencia,
              calcimp: r?.calcimp,
              reserva: r?.reserva,
              custo_id: r?.custo_id,
              ccusto_id: r?.ccusto_id,
              aplicacao_id: r?.aplicacao_id,
              planoconta_id: r?.planoconta_id,
              frota_id: r?.frota_id,
              observacao_id: r?.observacao_id,
              observacao: r?.observacao,
              dhsaldo: r?.dhsaldo,
              Origens: [
                {
                  documento_item_org_id: r?.id,
                },
              ],
            };

            if (Boolean(Estruturas?.length)) {
              item = { ...item, Estruturas };
            }
            if (Boolean(Processos?.length)) {
              item = { ...item, Processos };
            }
            if (Boolean(Vinculos?.length)) {
              item = { ...item, Vinculos };
            }

            return item;
          }),
        };
      }
      return form;
    } else {
      return { Registros: [{ documento_id, status_id: 18 }] };
    }
  }
};

export const checkInfosProduto = async ({ produto_id, ...rest }) => {
  const { data } = await api.get(`/Cadastros/Produto/${produto_id}`, {
    params: { ...rest, Midias: true, Descricao: true },
  });
  const imagem = data?.ProdutoImagems?.find((f) => f?.Midium?.tipo === 'IMAGEM')
    ?.Midium?.url;
  const descricao = data?.ProdutoDescricaos?.find(
    (f) => f?.tipo === 'COMPLETA' || f?.tipo === 'REDUZIDA'
  )?.descricao;
  return { ...data, imagem, descricao };
};

export const checkOrdemServico = async (documento_id) => {
  const { data } = await api.get(`/Cadastros/Documento/${documento_id}`, {
    params: { Itens: true },
  });
  const check = data?.DocumentoItems?.some(
    (i) =>
      i?.produto_id &&
      i?.Produto?.Celinha?.servico !== 'SIM' &&
      i?.quantidade <= i?.saldo
  );
  return check;
};

export const encryptURL = (word, key = 'E-Solution') => {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
};

export const decryptURL = (word, key = 'E-Solution') => {
  let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  try {
    const a = JSON.parse(bytes);
    return a;
  } catch (error) {
    return null;
  }
};

export const getDatePagto = (values) => {
  let start_date = moment(values?.start_date).format('YYYY-MM-DD');
  if (values?.fora === 'SEMANA') {
    start_date = moment().add(1, 'week').format('YYYY-MM-DD');
  }
  if (values?.fora === 'QUINZENA') {
    start_date = moment().add(15, 'days').format('YYYY-MM-DD');
  }
  if (values?.fora === 'MES') {
    start_date = moment().add(1, 'month').format('YYYY-MM-DD');
  }

  if (Boolean(values?.intervalop >= 0)) {
    start_date = moment(start_date)
      .add(values?.intervalop, 'days')
      .format('YYYY-MM-DD');
    if (values?.parcela !== 1) {
      start_date = moment(start_date)
        .add(values?.intervalo * (values?.parcela - 1), 'days')
        .format('YYYY-MM-DD');
    }
  } else {
    start_date = moment(start_date)
      .add(values?.intervalo * values?.parcela, 'days')
      .format('YYYY-MM-DD');
  }

  if (values?.fixar && values?.intervalo % 30 === 0) {
    const diaFixo = moment(values?.start_date).format('DD');
    start_date = moment(start_date).set('D', diaFixo).format('YYYY-MM-DD');
  }

  if (Boolean(values?.dia)) {
    while (Number(moment(start_date).format('DD')) !== values?.dia) {
      start_date = moment(start_date).add(1, 'day').format('YYYY-MM-DD');
    }
  }
  return start_date;
};
