import moment from 'moment';

const mock = [
  {
    field: 'dtemissao',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'dtmodificacao',
    renderCell: ({ value }) => renderModificacao(value),
  },
  {
    field: 'dtaprovacao',
    renderCell: ({ value }) =>
      value && moment(value).format('DD/MM/YYYY [às] HH:mm'),
  },
  {
    field: 'przent',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'status_id',
    renderCell: ({ value }) => renderStatus(value),
  },
  {
    field: 'tipo',
    renderCell: ({ value }) => renderTipo(value),
  },
];

const renderStatus = (value) => {
  const status =
    value === 2
      ? { text: 'ORDEM CANCELADA', color: '#858585' }
      : value === 6
      ? { text: 'ORDEM FINALIZADA', color: '#CFCFCF' }
      : value === 16
      ? { text: 'NOVO SERVIÇO', color: '#7FBFFF' }
      : value === 17
      ? { text: 'ORÇAMENTO ENVIADO', color: '#BF7FBF' }
      : value === 18
      ? { text: 'ORÇAMENTO APROVADO', color: '#7FFF7F' }
      : value === 19
      ? { text: 'ORÇAMENTO NÃO APROVADO', color: '#FF7F7F' }
      : value === 20
      ? { text: 'FATURAMENTO LIBERADO', color: '#FFBF7F' }
      : value === 21
      ? { text: 'AGUARDANDO PEÇAS', color: '#FFFF7F' }
      : value === 22
      ? { text: 'AGENDAMENTO', color: '#FFFFFF' }
      : value === 24
      ? { text: 'EMITIR ORÇAMENTO', color: '#7F7FFF' }
      : value === 25
      ? { text: 'ENVIAR ORÇAMENTO', color: '#FF7FFF' }
      : value === 26
      ? { text: 'EQUIPAMENTO DISPONIVEL', color: '#FFBF7F' }
      : value === 27
      ? { text: 'EQUIPAMENTO DESCARTADO', color: '#FFBF7F' }
      : value === 28
      ? { text: 'PEÇA INDISPONÍVEL', color: '#FF7F7F' }
      : value === 30
      ? { text: 'DIGITAÇÃO', color: '#FFFFFF' }
      : { text: '', color: '#FFFFFF' };

  const style = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: status?.color,
    borderRadius: '50px',
  };

  return (
    <div style={style}>
      <span>{status?.text}</span>
    </div>
  );
};

const renderModificacao = (value) => {
  const duration = moment.duration(moment().diff(moment(value))).asDays();

  const color =
    duration > 10 ? '#FF7F7F' : duration >= 5 ? '#FFFF7F' : '#FFFFFF';

  const style = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color,
    borderRadius: '50px',
  };
  return (
    <div style={style}>
      <span>{value && moment(value).format('DD/MM/YYYY [às] HH:mm')}</span>
    </div>
  );
};

const renderTipo = (value) => {
  const tipo =
    value === 'GARANTIA'
      ? { value, color: '#FF7F7F' }
      : { value, color: '#FFFFFF' };

  const style = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: tipo?.color,
    borderRadius: '50px',
  };

  return (
    <div style={style}>
      <span>{tipo?.value}</span>
    </div>
  );
};

export default mock;
