import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import Input from 'components/Input';

const JustificativaModal = ({ onSubmit }) => {
  const defaultValues = { motivo: '' };
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Informações Gerais
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          name="motivo"
          control={control}
          label="Justificativa de cancelamento"
          multiline
          rows={10}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default JustificativaModal;
