import { createContext, useState } from 'react';
import api from 'services/api';

export const GraficosContext = createContext();

export const GraficosProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [graficos, setGraficos] = useState({});

  const getGraficos = async ({ paths = [] }) => {
    try {
      setLoading(true);
      for (const path of paths) {
        const { data } = await api.get(`/Graficos/${path}`);
        setGraficos((prev) => ({ ...prev, [path]: data }));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <GraficosContext.Provider value={{ getGraficos, loading, graficos }}>
      {children}
    </GraficosContext.Provider>
  );
};
