import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { LocationOn, MiscellaneousServices } from '@mui/icons-material';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { OrdensContext } from 'contexts/OrdensContext';
import { useDialog, useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import CustomTabs from './Tabs';

const Equipamento = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [equipamento, setEquipamento] = useState(0);
  const { closeDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { state } = useLocation();
  const { drops } = useContext(DropsContext);
  const { deleteGrid } = useContext(GridContext);
  const { ordem, getLoading, getOrdem, postLoading, postDocumento } =
    useContext(OrdensContext);

  useEffect(() => {
    if (Boolean(state?.equipamento)) {
      getOrdem(state?.equipamento?.documento_id);
    } else {
      navigate(-1);
    }
  }, [state]);

  useEffect(() => {
    if (ordem) {
      const equipamento = ordem?.DocumentoEquipamentos?.find(
        (f) => f?.id === state?.equipamento?.id
      );
      setEquipamento(equipamento);
    }
  }, [ordem]);

  const onSubmit = (data) =>
    postDocumento({
      data,
      cb: () => {
        getOrdem(state?.equipamento?.documento_id);
        closeDialog();
      },
    });

  const onDelete = (data) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            ...data,
            cb: () => {
              getOrdem(state?.equipamento?.documento_id);
              closeModal();
            },
          })
        }
      />
    );

  const tabs = [
    {
      value: 0,
      key: 'Defeitos',
      label: 'Defeitos',
      icon: <MiscellaneousServices />,
    },
    {
      value: 1,
      key: 'Locais',
      label: 'Locais',
      icon: <LocationOn />,
    },
  ];

  return (
    <Container>
      <Header
        titulo={`Equipamento: ${
          state?.equipamento?.Equipamento?.descricao || ''
        }`}
      />
      <Card>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(_, v) => setTab(v)}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          {tabs.map(({ show = true, ...t }) => show && <Tab {...t} />)}
        </Tabs>
      </Card>
      <CustomTabs
        tab={tab}
        drops={drops}
        equipamento={equipamento}
        getLoading={getLoading}
        disableActions={state?.disableActions}
        loading={postLoading}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

export default Equipamento;
