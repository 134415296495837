import React, { useContext, useEffect } from 'react';
import {
  Grid,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Send } from '@mui/icons-material';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { OrdensContext } from 'contexts/OrdensContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';

const Tecnico = () => {
  const titulo = 'Direcionamento Técnico';
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { ordem, getLoading, getOrdem, postDocumento, postLoading } =
    useContext(OrdensContext);

  useEffect(() => {
    if (
      user?.nivel !== 'LIDER' &&
      user?.nivel !== 'DIRETORIA' &&
      user?.nivel !== 'GERENCIA' &&
      user?.nivel !== 'SUPERVISÃO'
    ) {
      navigate(-1);
    }
    if (state?.documento) {
      getOrdem(state?.documento?.id);
    } else {
      navigate(-1);
    }
  }, [state]);

  const onSubmit = (tecnico_id) => {
    const origens = [];
    ordem?.DocumentoItems?.map((di) =>
      di?.DocumentoItemOrigems?.map((dio) => origens?.push(dio))
    );
    const isContrato = origens?.some(
      (s) =>
        s?.natureza_operacao_id_origem === 51 &&
        [15, 24]?.includes(s?.especie_id_origem)
    );
    let data = {
      Servico: {
        ...ordem?.DocumentoServico,
        documento_id: state?.documento?.id,
        tecnico_id,
      },
    };
    if (state?.documento?.status_id !== 18) {
      const Registros = [{ documento_id: state?.documento?.id, status_id: 24 }];
      data = { ...data, Registros };
    }
    if (state?.documento?.tipo === 'GARANTIA' || isContrato) {
      const Registros = [{ documento_id: state?.documento?.id, status_id: 18 }];
      data = { ...data, Registros };
    }
    postDocumento({ data, cb: () => navigate(-1) });
  };

  const RenderCards = ({ tecnicos = [] }) =>
    tecnicos?.map((t, i) => (
      <Grid item xs={12} sm={6} key={i?.toString()}>
        <Card>
          <CardHeader
            avatar={<Avatar sx={{ bgcolor: 'primary.main' }} />}
            title={t?.name}
            action={
              <IconButton
                onClick={() => onSubmit(t?.id)}
                disabled={postLoading}
              >
                {postLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Send color="primary" />
                )}
              </IconButton>
            }
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              • {t?.envio} {t?.envio > 1 ? 'Orçamentos' : 'Orçamento'} à enviar
            </Typography>
            <Typography variant="body2" color="text.secondary">
              • {t?.aprovacao} {t?.aprovacao > 1 ? 'Orçamentos' : 'Orçamento'}{' '}
              aguardando aprovação
            </Typography>
            <Typography variant="body2" color="text.secondary">
              • {t?.pecas} {t?.pecas > 1 ? 'Orçamentos' : 'Orçamento'}{' '}
              aguardando peças
            </Typography>
            <Typography variant="body2" color="text.secondary">
              • {t?.andamento} {t?.andamento > 1 ? 'Serviços' : 'Serviço'} em
              Execução
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ));

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <RenderCards tecnicos={drops?.Tecnico} />
        </Grid>
      </Card>
    </Container>
  );
};

export default Tecnico;
