import React, { useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { OrdensContext } from 'contexts/OrdensContext';
import { useModal } from 'components/Modals';
import InputMask from 'components/InputMask';
import Button from 'components/Button';

const ValoresModal = ({ total = 0, callback, documento_id, itens = [] }) => {
  const defaultValues = {
    tipo: 'desconto_vlr',
    valor: '',
    percentual: '',
    itens,
  };
  const { closeModal } = useModal();
  const { control, handleSubmit, setValue } = useForm({ defaultValues });
  const { postDocumento, postLoading } = useContext(OrdensContext);

  const styles = {
    group: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
    },
    label: {
      flex: 1,
      m: 0,
    },
  };

  const tipos = [
    { label: 'Acessórias', value: 'despac' },
    { label: 'Desconto', value: 'desconto_vlr' },
    { label: 'Eventuais', value: 'eventuais' },
    { label: 'Frete', value: 'frete' },
    { label: 'Outros', value: 'outros' },
    { label: 'Seguro', value: 'seguro' },
  ];

  const calcPercentual = (values) => {
    const valor = values?.valor || 0;
    const percentual = parseFloat(((valor * 100) / total).toFixed(2));
    setValue('percentual', percentual || '');
  };

  const calcValor = (values) => {
    const percentual = values?.percentual || 0;
    const valor = parseFloat(((total * percentual) / 100).toFixed(2));
    setValue('valor', valor || '');
  };

  const onConfirm = (values) => {
    const valor = values?.valor || 0;
    let valor_lancado = valor || 0;
    const Itens = itens?.map((item, index) => {
      const preco = item?.preco || 0;
      const quantidade = item?.quantidade || 0;
      const average = parseFloat(((preco * quantidade) / total).toFixed(2));
      const average_total = parseFloat((valor * average).toFixed(2));
      const value =
        index + 1 === itens?.length
          ? parseFloat(valor_lancado?.toFixed(2))
          : parseFloat(average_total?.toFixed(2));
      valor_lancado -= value;
      return {
        id: item?.id,
        documento_id,
        [values?.tipo]: values?.tipo === 'desconto_vlr' ? -value : value,
      };
    });
    const data = { Itens };
    const cb = () => {
      if (Boolean(callback)) {
        callback();
      }
      closeModal();
    };
    postDocumento({ data, cb });
  };

  return (
    <>
      <DialogTitle>Adicionar Valores</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="tipo"
              render={({ field }) => (
                <RadioGroup {...field} sx={styles?.group}>
                  {tipos?.map((t) => (
                    <FormControlLabel
                      sx={styles?.label}
                      key={t?.value}
                      value={t?.value}
                      label={t?.label}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="percentual"
              control={control}
              label="Percentual"
              onBlur={handleSubmit(calcValor)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="valor"
              control={control}
              label="Valor"
              onBlur={handleSubmit(calcPercentual)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onConfirm)}
          loading={postLoading}
          variant="contained"
        >
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary" variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default ValoresModal;
