import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import api from 'services/api';
import socket from 'utils/socket';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();
  const modulo = 'SERVICOS';

  const isAuthenticated = !!user;

  useEffect(() => {
    const refresh_token = localStorage.getItem('ESolution:refreshToken');
    if (!isAuthenticated && refresh_token) {
      refresh({ refresh_token });
    }
  }, []);

  const login = async (payload) => {
    try {
      setLoginLoading(true);
      const body = { ...payload, modulo };
      const { data } = await api.post('/Auth/Login', body);
      localStorage.setItem('ESolution:accessToken', data.access_token);
      localStorage.setItem('ESolution:refreshToken', data.refresh_token);
      setUser(data);
    } catch (error) {
      localStorage.clear();
    } finally {
      setLoginLoading(false);
    }
  };

  const refresh = async (payload) => {
    try {
      setLoginLoading(true);
      const body = { ...payload, modulo };
      const { data } = await api.post('/Auth/Refresh', body);
      localStorage.setItem('ESolution:accessToken', data.access_token);
      localStorage.setItem('ESolution:refreshToken', data.refresh_token);
      setUser(data);
    } catch (error) {
      localStorage.clear();
    } finally {
      setLoginLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('ESolution:accessToken');
    localStorage.removeItem('ESolution:refreshToken');
    setUser(null);
    navigate('/', { replace: true });
  };

  const postUser = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Auth/User', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        loginLoading,
        login,
        refresh,
        logout,
        postUser,
        postLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
