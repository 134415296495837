import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Radio,
  InputLabel,
} from '@mui/material';
import { useModal } from 'components/Modals';
import Button from 'components/Button';

const GeraçãoModal = ({ onSubmit }) => {
  const { closeModal } = useModal();
  const [type, setType] = useState('Cupom');

  return (
    <>
      <DialogTitle>Gerar Documento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12} display="flex" alignItems="center">
            <Radio
              name="cupom"
              id="cupom"
              color="primary"
              size="small"
              checked={type === 'Cupom'}
              onChange={() => setType('Cupom')}
            />
            <InputLabel htmlFor="cupom">Cupom</InputLabel>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Radio
              name="fatura"
              id="fatura"
              color="primary"
              size="small"
              checked={type === 'Fatura'}
              onChange={() => setType('Fatura')}
            />
            <InputLabel htmlFor="fatura">Fatura</InputLabel>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Radio
              name="nfe"
              id="nfe"
              color="primary"
              size="small"
              checked={type === 'NFe'}
              onChange={() => setType('NFe')}
            />
            <InputLabel htmlFor="nfe">NF-e</InputLabel>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Radio
              name="cfe"
              id="cfe"
              color="primary"
              size="small"
              checked={type === 'CFe'}
              onChange={() => setType('CFe')}
            />
            <InputLabel htmlFor="cfe">CF-e</InputLabel>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Radio
              name="nfse"
              id="nfse"
              color="primary"
              size="small"
              checked={type === 'NFSe'}
              onChange={() => setType('NFSe')}
            />
            <InputLabel htmlFor="nfse">NFS-e</InputLabel>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onSubmit(type);
            closeModal();
          }}
        >
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default GeraçãoModal;
