import { createContext, useState } from 'react';
import api from 'services/api';

export const GridContext = createContext();

export const GridProvider = ({ children }) => {
  const [configLoading, setConfigLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const configGrid = async ({ rotina, colunas, cb }) => {
    try {
      setConfigLoading(true);
      if (!!colunas) {
        await api.post('/Config', { rotina, colunas });
      } else {
        await api.delete('/Config', { params: { rotina } });
      }
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setConfigLoading(false);
    }
  };

  const postGrid = async ({ data, rotina, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Grid/Manutencao', { data, rotina });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteGrid = async ({ rotina, id, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete('/Grid', { params: { rotina, id } });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <GridContext.Provider
      value={{
        configGrid,
        configLoading,
        deleteGrid,
        deleteLoading,
        postGrid,
        postLoading,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};
