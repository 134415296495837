import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { dropTipoServico } from 'utils/drops';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import styles from '../styles';

const Serviço = ({
  drops,
  loading,
  onSubmit,
  documento = {},
  disableActions,
}) => {
  const defaultValues = {
    documento_id: documento?.id,
    garantia: documento?.DocumentoServico?.garantia || '',
    assunto: documento?.DocumentoServico?.assunto || '',
    mail_contato: documento?.DocumentoServico?.mail_contato || '',
    defeito: documento?.DocumentoServico?.defeito || '',
    solicitacao: documento?.DocumentoServico?.solicitacao || '',
    tipo: documento?.DocumentoServico?.tipo || null,
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <Card title="Serviço" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input name="solicitacao" control={control} label="Solicitação" />
        </Grid>
        <Grid item xs={12}>
          <Input name="defeito" control={control} label="Relato do Defeito" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="mail_contato"
            control={control}
            label="Email de Contato"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="assunto" control={control} label="Assunto" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            name="tipo"
            control={control}
            label="Tipo do Serviço"
            options={dropTipoServico}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            name="garantia"
            control={control}
            label="Garantia (Dias)"
            type="number"
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={handleSubmit((Servico) => onSubmit({ Servico }))}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Serviço;
