export const dropBoolean = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

export const dropTipoServico = [
  { value: 'GARANTIA', label: 'GARANTIA' },
  { value: 'CORRETIVA', label: 'CORRETIVA' },
  { value: 'PREVENTIVA', label: 'PREVENTIVA' },
];

export const dropTipoPessoa = [
  { value: 'FISICA', label: 'FÍSICA' },
  { value: 'JURIDICA', label: 'JURÍDICA' },
];

export const dropTipoPerfil = [
  { value: 'CLIENTE', label: 'CLIENTE' },
  { value: 'FORNECEDOR', label: 'FORNECEDOR' },
  { value: 'TRANSPORTADOR', label: 'TRANSPORTADOR' },
  { value: 'FILIAL', label: 'FILIAL' },
  { value: 'USUARIO', label: 'USUARIO' },
  { value: 'FUNCIONARIO', label: 'FUNCIONARIO' },
  { value: 'MOTORISTA', label: 'MOTORISTA' },
  { value: 'VENDEDOR', label: 'VENDEDOR' },
  { value: 'REPRESENTANTE', label: 'REPRESENTANTE' },
  { value: 'INSTITUICAO FINANCEIRA', label: 'INSTITUICAO FINANCEIRA' },
  { value: 'OPERADORA DE CARTAO', label: 'OPERADORA DE CARTAO' },
  { value: 'CONTATO', label: 'CONTATO' },
];

export const dropStatus = [
  { value: 2, label: 'ORDEM CANCELADA' },
  { value: 6, label: 'ORDEM FINALIZADA' },
  { value: 16, label: 'NOVO SERVIÇO' },
  { value: 17, label: 'ORÇAMENTO ENVIADO' },
  { value: 18, label: 'ORÇAMENTO APROVADO' },
  { value: 19, label: 'ORÇAMENTO NÃO APROVADO' },
  { value: 20, label: 'FATURAMENTO LIBERADO' },
  { value: 21, label: 'AGUARDANDO PEÇAS' },
  { value: 22, label: 'AGENDAMENTO' },
  { value: 24, label: 'EMITIR ORÇAMENTO' },
  { value: 25, label: 'ENVIAR ORÇAMENTO' },
  { value: 26, label: 'EQUIPAMENTO DISPONIVEL' },
  { value: 27, label: 'EQUIPAMENTO DESCARTADO' },
  { value: 28, label: 'PEÇA INDISPONÍVEL' },
  { value: 30, label: 'DIGITAÇÃO' },
];

export const dropTipoMovimentacao = [
  { value: 'UNIDADE', label: 'UNIDADE' },
  { value: 'ESTRUTURA', label: 'ESTRUTURA' },
];

export const dropAlimentacao = [
  { value: '127 Volts', label: '127 Volts' },
  { value: '220 Volts', label: '220 Volts' },
  { value: '380 Volts', label: '380 Volts' },
  { value: 'Bivolt', label: 'Bivolt' },
];

export const dropDesconto = [
  { value: 'SEM DESCONTO', label: 'SEM DESCONTO' },
  { value: 'DESCONTO (VALOR)', label: 'DESCONTO (VALOR)' },
  { value: 'DESCONTO (%)', label: 'DESCONTO (%)' },
];
