import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Edit, Settings } from '@mui/icons-material';
import {
  findOnArray,
  summarizer,
  getDatePagto,
  decryptURL,
} from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { VendaContext } from 'contexts/VendaContext';
import { OrdensContext } from 'contexts/OrdensContext';
import { useDialog, useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Card from 'components/Card';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Header from 'components/Header';
import Checkbox from 'components/Checkbox';
import Dropdown from 'components/Dropdown';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import ValoresModal from './Modals/ValoresModal';
import PagamentoModal from './Modals/PagamentoModal';
import styles from './styles';
import moment from 'moment';

const OrdemFaturamento = () => {
  const titulo = 'Faturamento';
  const defaultValues = {
    especie_id: null,
    cadastro_id: null,
    documento: '',
    tipo_servico: '',
    equipamentos: [],
    // ITENS
    itens: [],
    // PAGAMENTO
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    valor: '',
    troco: '',
    autenticacao: '',
    fixar: false,
    pagamento: [],
    // ADICIONAIS
    vendedor_id: null,
    observacao: '',
    dadosadc: '',
  };
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const { openModal, closeModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { control, handleSubmit, watch, setValue, getValues } = useForm({
    defaultValues,
  });
  const { getOrdem, getLoading, postFatura, postLoading } =
    useContext(OrdensContext);
  const { contas } = useContext(VendaContext);
  const { drops } = useContext(DropsContext);
  const { user } = useContext(AuthContext);
  const { hash } = useParams();
  const decrypt = decryptURL(hash);
  const especies =
    drops?.EspecieDocumento?.filter(
      (f) =>
        f?.Grupo === 'SAIDA' && f?.natureza === 'FATURA' && Boolean(f?.serie_id)
    ) || [];
  const modalidade = findOnArray(
    watch('forma_pagto_id'),
    drops?.FormaDePagamento,
    'modalidade'
  );
  const isCaixa = Boolean(contas?.cxconta_id) && Boolean(contas?.seqcaixa);
  const isCartao =
    modalidade === '03-CARTAO DE CREDITO' ||
    modalidade === '04-CARTAO DE DEBITO';
  const isEquipamentos = Boolean(watch('equipamentos')?.length);
  const total_itens = summarizer(watch('itens'), 'subtotal');
  const total_pagamento = summarizer(watch('pagamento'), 'valor');
  const gridProps = {
    hideFooter: true,
    autoHeight: true,
    density: 'compact',
    disableSelectionOnClick: true,
    disableColumnMenu: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
    getRowHeight: () => 'auto',
    localeText: { noRowsLabel: 'Nenhum Registro.' },
  };
  const COLOR = { SOMA: 'success.main', SUBTRAI: 'error', NENHUMA: 'grey' };
  const VALOR = { SOMA: 1, SUBTRAI: -1, NENHUMA: 1 };
  const SUBTOTAL = { SOMA: 1, SUBTRAI: -1, NENHUMA: 0 };

  const loadData = () => getOrdem(decrypt?.id, renderValues);

  const renderValues = (documento) => {
    const oldValues = getValues();
    const documentoItens = documento?.DocumentoItems || [];
    const itens = documentoItens
      ?.map((di) => {
        const gerados = di?.Destinos?.filter(
          (d) =>
            d?.especie_natureza === 'FATURA' && d?.especie_grupo === 'SAIDA'
        );
        const quantidade_gerada = summarizer(gerados, 'quantidade') || 0;
        const preco = di?.preco || 0;
        const quantidade = (di?.quantidade || 0) - (quantidade_gerada || 0);
        const desconto_vlr = di?.desconto_vlr || 0;
        const frete = di?.frete || 0;
        const seguro = di?.seguro || 0;
        const despac = di?.despac || 0;
        const eventuais = di?.eventuais || 0;
        const outros = di?.outros || 0;
        const servico = Boolean(di?.Cfo?.servico === 'SIM');
        const impostos = di?.DocumentoItemImpostos?.map((dii) => {
          const operacao = dii?.SituacaoTributarium?.operacaotd;
          return {
            operacao,
            color: COLOR[operacao],
            descricao: dii?.Tributo?.descricao,
            valor: dii?.valor * VALOR[operacao],
            subtotal: dii?.valor * SUBTOTAL[operacao],
          };
        });
        const imposto = summarizer(impostos, 'subtotal') || 0;
        return {
          id: di?.id,
          descricao: di?.descricao,
          referencia: di?.referencia,
          produto_id: di?.produto_id,
          um: di?.UnidadeMedida?.sigla || '',
          precobase_id: di?.precobase_id,
          cfo_id: di?.cfo_id,
          cfiscal_id: di?.cfiscal_id,
          planoconta_id: di?.planoconta_id,
          unidade_medida_id: di?.unidade_medida_id,
          quantidade,
          servico,
          preco,
          desconto_vlr,
          frete,
          seguro,
          despac,
          eventuais,
          outros,
          impostos,
          imposto,
          subtotal:
            preco * quantidade +
            desconto_vlr +
            frete +
            seguro +
            despac +
            eventuais +
            outros +
            imposto,
        };
      })
      ?.filter((f) => Boolean(f?.quantidade));
    const equipamentos = (documento?.DocumentoEquipamentos || [])?.map(
      (de) => ({
        referencia: de?.Equipamento?.referencia,
        descricao: de?.Equipamento?.descricao,
        marca: de?.Equipamento?.marca,
        sn: de?.sn,
      })
    );
    const forma_pagto_id = documento?.DocumentoFinanceiros?.find((f) =>
      Boolean(f?.forma_pagto_id)
    )?.forma_pagto_id;
    const condicao_pagamento_id =
      documento?.DocumentoSaida?.condicao_pagamento_id || null;
    const isNFe =
      documentoItens?.some((di) =>
        di?.DocumentoItemOrigems?.some((dio) => dio?.especie_id_origem === 15)
      ) && Boolean(findOnArray(10, especies, 'value'));
    const isFat =
      documentoItens?.some((di) =>
        di?.DocumentoItemOrigems?.some((dio) => dio?.especie_id_origem === 24)
      ) && Boolean(findOnArray(25, especies, 'value'));
    if (!Boolean(oldValues?.especie_id)) {
      setValue('especie_id', isNFe ? 10 : isFat ? 25 : null);
    }
    setValue('equipamentos', equipamentos);
    if (!Boolean(oldValues?.forma_pagto_id)) {
      setValue('forma_pagto_id', forma_pagto_id);
    }
    setValue('documento', documento?.documento || '');
    setValue('cadastro_id', documento?.cadastro_id || null);
    setValue('tipo_servico', documento?.DocumentoServico?.tipo || '');
    if (!Boolean(oldValues?.condicao_pagamento_id)) {
      setValue('condicao_pagamento_id', condicao_pagamento_id);
    }
    setValue(
      'vendedor_id',
      documento?.DocumentoSaida?.vendedor_id || user?.filial_id
    );
    setValue('itens', itens);
  };

  const renderNumDoc = (cadastro_id) => {
    let documento = findOnArray(
      cadastro_id,
      drops?.Entidade,
      'documento'
    )?.replace(/\D/g, '');
    if (documento?.length === 11) {
      documento = documento?.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      );
    }
    if (documento?.length === 14) {
      documento = documento?.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );
    }
    return documento;
  };

  const renderCalculo = (values) => {
    setValue('valor', total_itens);
    setValue('pagamento', []);
    if (
      Boolean(total_itens > 0) &&
      Boolean(values?.forma_pagto_id) &&
      Boolean(values?.condicao_pagamento_id)
    ) {
      handleSubmit(onChangePagto)();
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    handleSubmit(renderCalculo)();
  }, [watch('itens')]);

  useEffect(() => {
    const isFat = watch('especie_id') == 25;
    const itens = watch('itens')?.map((item) => {
      const impostos = item?.impostos?.map((imp) => {
        const operacao = isFat ? 'NENHUMA' : imp?.operacao;
        return {
          operacao: imp?.operacao,
          color: COLOR[operacao],
          descricao: imp?.descricao,
          valor: Math.abs(imp?.valor) * VALOR[operacao],
          subtotal: Math.abs(imp?.valor) * SUBTOTAL[operacao],
        };
      });
      const imposto = summarizer(impostos, 'subtotal') || 0;
      return {
        ...item,
        impostos,
        imposto,
        subtotal:
          item?.preco * item?.quantidade +
          item?.desconto_vlr +
          item?.frete +
          item?.seguro +
          item?.despac +
          item?.eventuais +
          item?.outros +
          imposto,
      };
    });
    setValue('itens', itens);
  }, [watch('especie_id')]);

  useEffect(() => {
    const sobra = total_itens - summarizer(watch('pagamento'), 'valor');
    if (sobra > 0) {
      setValue('valor', sobra);
    } else {
      setValue('valor', '');
    }
  }, [watch('pagamento')]);

  const onSubmit = (values) => {
    const especie_id = values?.especie_id ? Number(values?.especie_id) : null;
    const especie = findOnArray(especie_id, drops?.EspecieDocumento);
    const isFiscal = especie?.fiscal === 'SIM';
    const modelofiscal_id = especie?.modelofiscal_id;
    const modelofiscal_id_serv = drops?.Modelofiscal?.find(
      (f) => f?.modelo === 'NFS-e'
    )?.value;
    const serie_id = drops?.Serie?.find(
      (f) =>
        f?.modelofiscal_id === modelofiscal_id &&
        (especie_id === 26 ? f?.cxconta_id === contas?.cxconta_id : true)
    )?.value;
    const serie_id_serv = drops?.Serie?.find(
      (f) =>
        f?.modelofiscal_id === modelofiscal_id_serv &&
        (especie_id === 26 ? f?.cxconta_id === contas?.cxconta_id : true)
    )?.value;

    const Documento = {
      especie_id,
      natureza_operacao_id: 6,
      conferido: 'SIM',
      users_id: user?.id,
      filial_id: user?.filial_id,
      cadastro_id: values?.cadastro_id,
      dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
      observacao: values?.observacao,
      dadosadc: values?.dadosadc,
      usofinal: 'SIM',
    };
    const Saida = {
      serie_id,
      condicao_pagamento_id: values?.condicao_pagamento_id,
      vendedor_id: values?.vendedor_id,
      dtsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
      documento: renderNumDoc(values?.cadastro_id),
    };
    const Itens = values?.itens?.map(({ id, ...item }) => ({
      ...item,
      Origens: id ? [{ documento_item_org_id: id }] : [],
      planoconta_id: contas?.planoconta_id,
    }));
    const ItensNota = Itens?.filter((f) => !Boolean(f?.servico))?.map(
      (m, i) => ({
        ...m,
        sequencia: i + 1,
      })
    );
    const ItensServico = Itens?.filter((f) => Boolean(f?.servico))?.map(
      (m, i) => ({
        ...m,
        sequencia: i + 1,
      })
    );
    const Financeiros = values?.pagamento
      ?.filter((f) => (isCaixa ? f?.indpag === '1-A PRAZO' : true))
      ?.map((a) => ({ ...a, fixo: true }));
    const Transportes = [
      {
        tipotransp: 'DESPACHO',
        viatransp: '01 - RODOVIARIO',
        fretepc: '9 - SEM OCORRENCIA DE TRANSPORTE',
        natureza: '9 - OUTRAS',
      },
    ];
    const Emitir = { tpamb: 1 };
    const Origens = [{ documento_id: decrypt?.id }];
    const nota = {
      Documento,
      Saida,
      Itens: isFiscal ? ItensNota : Itens,
      Financeiros: isFiscal ? [] : Financeiros,
      Transportes: isFiscal ? Transportes : [],
      Origens,
      Emitir,
    };
    const servico = {
      Documento: {
        ...Documento,
        especie_id: 10,
        natureza_operacao_id: 11,
      },
      Itens: ItensServico,
      Saida: { ...Saida, serie_id: serie_id_serv },
      Financeiros,
      Transportes: isFiscal ? Transportes : [],
      Origens,
      Emitir,
    };
    let lancamento = {
      cxconta_id: contas?.cxconta_id,
      seqcaixa: contas?.seqcaixa,
      formas: values?.pagamento,
    };

    if (values?.troco > 0) {
      lancamento = {
        ...lancamento,
        troco: parseFloat(values?.troco?.toFixed(2)),
        plano_troco_id: contas?.plano_troco_id,
      };
    }

    let data = {};
    if (isCaixa) {
      data = { ...data, lancamento };
    }
    if (!isFiscal) {
      data = { ...data, nota };
    } else {
      if (Boolean(ItensNota?.length) && summarizer(ItensNota, 'subtotal') > 0) {
        data = { ...data, nota };
      }
      if (
        Boolean(ItensServico?.length) &&
        summarizer(ItensServico, 'subtotal') > 0
      ) {
        data = { ...data, servico };

        if (Boolean(data?.nota?.Documento)) {
          data['nota']['Documento']['natureza_operacao_id'] = 80;
        }
      }
    }
    postFatura({ data, cb: () => navigate(-1) });
  };

  const onChangePagto = (values) => {
    const pagamento = values?.pagamento;
    const forma_pagto_id = values?.forma_pagto_id;
    const condicao_pagamento_id = values?.condicao_pagamento_id;
    const fixar = values?.fixar;
    const condicoes =
      findOnArray(forma_pagto_id, drops?.FormaDePagamento, 'condicoes') || [];
    const forma_pagto =
      findOnArray(forma_pagto_id, drops?.FormaDePagamento) || null;
    const cond_pagto = findOnArray(condicao_pagamento_id, condicoes);
    const valor_total = summarizer(values?.itens, 'subtotal');
    if (!Boolean(values?.valor)) {
      return toastWarning('Valor não informado');
    }
    if (!Boolean(forma_pagto_id)) {
      return toastWarning('Forma de pagamento não informada');
    }
    if (!Boolean(condicao_pagamento_id)) {
      return toastWarning('Condição de pagamento não informada');
    }
    if (!Boolean(cond_pagto)) {
      return toastWarning('Condição de pagamento não encontrada');
    }
    if (valor_total > 0) {
      let valor_lancado = 0;
      for (let parcela = 1; parcela <= cond_pagto?.nvezes; parcela++) {
        const valor = parseFloat(
          (values?.valor / cond_pagto?.nvezes).toFixed(2)
        );
        pagamento.push({
          parcela: pagamento?.length + 1,
          dtvenc: getDatePagto({ ...cond_pagto, parcela, fixar }),
          valor:
            parcela === cond_pagto?.nvezes
              ? parseFloat((values?.valor - valor_lancado)?.toFixed(2))
              : valor,
          forma_pagto_id,
          forma_pagto: forma_pagto?.label,
          indpag: forma_pagto?.indpag,
          autenticacao: values?.autenticacao,
        });
        valor_lancado += valor;
      }
    }
    setValue('pagamento', pagamento);
    const troco =
      summarizer(
        pagamento?.filter((f) => f?.indpag === '0-A VISTA'),
        'valor'
      ) - valor_total;
    if (troco > 0) {
      setValue('troco', troco);
    }
  };

  const onEditPagto = (values) => {
    const pagamento = watch('pagamento')?.map((p) =>
      p?.parcela === values?.parcela ? { ...p, ...values } : p
    );
    setValue('pagamento', pagamento);
    closeDialog();
  };

  const onDeletePagto = (values) => {
    const pagamento = watch('pagamento')
      ?.filter((p) => p?.parcela !== values?.parcela)
      ?.map((pp, i) => ({ ...pp, parcela: i + 1 }));
    setValue('pagamento', pagamento);
    closeModal();
  };

  const onDeleteItem = (id) => {
    const { itens } = getValues();
    setValue(
      'itens',
      itens?.filter((f) => f?.id !== id)
    );
    closeModal();
  };

  const onSelectionModelChange = (ids) =>
    setSelected(
      ids
        ?.map((id) => watch('itens')?.find((f) => f?.id === id))
        ?.filter(Boolean)
    );

  const PopUp = ({ anchorEl, setAnchorEl, impostos = [] }) => (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      {impostos?.map((m, i) => (
        <Box key={i?.toString()} sx={styles?.popover}>
          <Typography variant="subtitle2" color={m?.color}>
            {m?.descricao}:
          </Typography>
          <Typography variant="subtitle2" color={m?.color}>
            {m?.valor?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
        </Box>
      ))}
    </Popover>
  );

  const ItemCell = ({ row }) => (
    <Box sx={styles?.cardContainer}>
      <Box sx={styles?.card}>
        <Typography sx={styles?.lineLabel(1)} variant="body1">
          {row?.descricao}
        </Typography>
        {Boolean(row?.referencia) && (
          <Box sx={styles?.line}>
            <Typography sx={styles?.lineLabel(2)} variant="caption">
              Referência:{' '}
            </Typography>
            <Typography sx={styles?.lineValue(5)} variant="caption">
              {row?.referencia}
            </Typography>
          </Box>
        )}
      </Box>
      {Boolean(row?.servico) && (
        <Chip
          label="Serviço"
          icon={<Settings />}
          size="small"
          color="primary"
        />
      )}
    </Box>
  );

  const ValoresCell = ({ row }) => {
    const valores = [
      { label: 'Desconto', value: row?.desconto_vlr || 0 },
      { label: 'Impostos', value: row?.imposto || 0 },
      { label: 'Frete', value: row?.frete || 0 },
      { label: 'Seguro', value: row?.seguro || 0 },
      { label: 'Acessórias', value: row?.despac || 0 },
      { label: 'Eventuais', value: row?.eventuais || 0 },
      { label: 'Outros', value: row?.outros || 0 },
    ];
    return (
      <Box sx={styles?.card}>
        {valores?.map((v, i) => {
          const [anchorEl, setAnchorEl] = useState(null);
          const link = i === 1 && row?.impostos?.length;
          if (i < 2 || Boolean(v?.value)) {
            return (
              <Box key={i?.toString()} sx={styles?.line}>
                <Typography
                  variant="body2"
                  sx={styles?.lineLabel(1, link)}
                  onClick={(e) => link && setAnchorEl(e?.currentTarget)}
                >
                  {v?.label}:{' '}
                </Typography>
                <Typography
                  variant="body2"
                  sx={styles?.lineValue(0, link, true)}
                  onClick={(e) => link && setAnchorEl(e?.currentTarget)}
                >
                  {v?.value?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
                <PopUp
                  anchorEl={anchorEl}
                  impostos={row?.impostos}
                  setAnchorEl={setAnchorEl}
                />
              </Box>
            );
          }
          return null;
        })}
      </Box>
    );
  };

  const ParcelaCell = ({ row }) => (
    <Box sx={styles?.cardContainer}>
      <Box sx={styles?.card}>
        <Typography sx={styles?.lineLabel(1)} variant="body1">
          {row?.parcela}ª Parcela
        </Typography>
        {Boolean(row?.dtvenc) && (
          <Box sx={styles?.line}>
            <Typography sx={styles?.lineLabel(2)} variant="caption">
              Vencimento:{' '}
            </Typography>
            <Typography sx={styles?.lineValue(3)} variant="caption">
              {moment(row?.dtvenc).format('DD/MM/YYYY')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  const PagamentoCell = ({ row }) => (
    <Box sx={styles?.cardContainer}>
      <Box sx={styles?.card}>
        <Typography sx={styles?.lineLabel(1)} variant="body1">
          {row?.forma_pagto}
        </Typography>
        {Boolean(row?.autenticacao) && (
          <Box sx={styles?.line}>
            <Typography sx={styles?.lineLabel(2)} variant="caption">
              SKU:{' '}
            </Typography>
            <Typography sx={styles?.lineValue(3)} variant="caption">
              {row?.autenticacao}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} display="flex">
          <Card title="Espécie" style={styles?.card}>
            <Controller
              control={control}
              name="especie_id"
              render={({ field }) => (
                <RadioGroup {...field}>
                  {especies?.map((t) => (
                    <FormControlLabel
                      key={t?.value?.toString()}
                      value={t?.value}
                      label={t?.label}
                      disabled={
                        t?.value === 26 &&
                        watch('itens')?.every((a) => a?.servico)
                      }
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={isEquipamentos ? 4 : 8} display="flex">
          <Card title="Serviço" style={styles?.card}>
            <Box sx={styles?.line}>
              <Typography sx={styles?.lineLabel(1)}>Tipo:</Typography>
              <Typography style={styles?.lineValue(3)}>
                {watch('tipo_servico') || '-'}
              </Typography>
            </Box>
            <Box sx={styles?.line}>
              <Typography sx={styles?.lineLabel(1)}>Cliente:</Typography>
              <Typography style={styles?.lineValue(3)}>
                {findOnArray(watch('cadastro_id'), drops?.Entidade, 'label') ||
                  '-'}
              </Typography>
            </Box>
            <Box sx={styles?.line}>
              <Typography sx={styles?.lineLabel(1)}>Nº da Ordem:</Typography>
              <Typography style={styles?.lineValue(3)}>
                {watch('documento') || '-'}
              </Typography>
            </Box>
          </Card>
        </Grid>
        {isEquipamentos && (
          <Grid item xs={12} sm={4} display="flex">
            <Card title="Equipamento(s)" style={styles?.card}>
              {watch('equipamentos')?.map((e, i) => (
                <Box key={i?.toString()}>
                  {Boolean(i) && <Divider />}
                  <Typography sx={styles?.lineLabel(1)} variant="body1">
                    {e?.descricao}
                  </Typography>
                  <Box sx={styles?.line}>
                    <Typography sx={styles?.lineLabel(1)} variant="caption">
                      Referência:{' '}
                    </Typography>
                    <Typography sx={styles?.lineValue(3)} variant="caption">
                      {e?.referencia || '-'}
                    </Typography>
                  </Box>
                  <Box sx={styles?.line}>
                    <Typography sx={styles?.lineLabel(1)} variant="caption">
                      Serial Number:{' '}
                    </Typography>
                    <Typography sx={styles?.lineValue(3)} variant="caption">
                      {e?.sn || '-'}
                    </Typography>
                  </Box>
                  <Box sx={styles?.line}>
                    <Typography sx={styles?.lineLabel(1)} variant="caption">
                      Marca:{' '}
                    </Typography>
                    <Typography sx={styles?.lineValue(3)} variant="caption">
                      {e?.marca || '-'}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card title="Itens">
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    Boolean(selected?.length)
                      ? openModal(
                          <ValoresModal
                            itens={selected}
                            total={total_itens}
                            callback={loadData}
                            documento_id={decrypt?.id}
                          />
                        )
                      : toastWarning('Selecione ao menos um item')
                  }
                >
                  ADICIONAR VALORES
                </Button>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={watch('itens')}
                  columns={[
                    {
                      field: 'item',
                      headerName: 'Item',
                      flex: 1,
                      sortable: false,
                      renderCell: (props) => <ItemCell {...props} />,
                    },
                    {
                      field: 'quantidade',
                      headerName: 'Quantidade',
                      type: 'number',
                      width: 150,
                      sortable: false,
                      valueGetter: ({ row }) =>
                        row?.quantidade + (row?.um ? ` ${row?.um}` : ''),
                    },
                    {
                      field: 'preco',
                      headerName: 'Preço Unitário',
                      type: 'number',
                      width: 200,
                      sortable: false,
                      valueGetter: ({ value }) =>
                        (value || 0)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        }),
                    },
                    {
                      field: 'valores',
                      headerName: 'Outros Valores',
                      type: 'number',
                      width: 300,
                      sortable: false,
                      renderCell: (props) => <ValoresCell {...props} />,
                    },
                    {
                      field: 'subtotal',
                      headerName: 'Subtotal',
                      type: 'number',
                      width: 200,
                      sortable: false,
                      valueGetter: ({ value }) =>
                        (value || 0)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        }),
                    },
                    {
                      field: 'actions',
                      headerName: 'Ações',
                      type: 'actions',
                      width: 70,
                      getActions: ({ id }) => [
                        <GridActionsCellItem
                          icon={<Icon>delete</Icon>}
                          label="Excluir"
                          onClick={() =>
                            openModal(
                              <DeleteModal onSubmit={() => onDeleteItem(id)} />
                            )
                          }
                        />,
                      ],
                    },
                  ]}
                  checkboxSelection
                  selectionModel={selected?.map((m) => m?.id)}
                  onSelectionModelChange={onSelectionModelChange}
                  {...gridProps}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" color="primary">
                  Total:{' '}
                  {total_itens?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card title="Pagamento">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} display="flex">
                <Card style={styles?.card}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Dropdown
                        name="forma_pagto_id"
                        control={control}
                        label="Forma de Pagamento"
                        onValueChange={() => {
                          setValue('autenticacao', '');
                          setValue('condicao_pagamento_id', null);
                        }}
                        options={drops?.FormaDePagamento?.filter(
                          (f) =>
                            f?.caixa === 'SIM' &&
                            f?.modulo !== 'COMPRAS' &&
                            f?.modulo !== 'CHEQUES'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Dropdown
                        name="condicao_pagamento_id"
                        control={control}
                        label="Condição de Pagamento"
                        options={findOnArray(
                          watch('forma_pagto_id'),
                          drops?.FormaDePagamento,
                          'condicoes'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputMask name="valor" control={control} label="Valor" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Input
                        name="autenticacao"
                        control={control}
                        label="SKU (Autenticação)"
                        disabled={!isCartao}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Checkbox
                        name="fixar"
                        control={control}
                        label="Fixar Dia"
                      />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onChangePagto)}
                      >
                        CALCULAR
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} display="flex">
                <Card style={styles?.card}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DataGrid
                        rows={watch('pagamento')}
                        columns={[
                          {
                            field: 'parcela',
                            headerName: 'Parcela',
                            flex: 2,
                            sortable: false,
                            renderCell: (props) => <ParcelaCell {...props} />,
                          },
                          {
                            field: 'forma_pagto',
                            headerName: 'Forma de Pagamento',
                            flex: 2,
                            sortable: false,
                            renderCell: (props) => <PagamentoCell {...props} />,
                          },
                          {
                            field: 'valor',
                            headerName: 'Valor',
                            type: 'number',
                            flex: 1,
                            sortable: false,
                            valueGetter: ({ value }) =>
                              (value || 0)?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              }),
                          },
                          {
                            field: 'actions',
                            type: 'actions',
                            width: 100,
                            headerName: 'Ações',
                            getActions: ({ row }) => {
                              return [
                                <GridActionsCellItem
                                  icon={<Edit />}
                                  label="Editar"
                                  onClick={() =>
                                    openDialog(
                                      <PagamentoModal
                                        item={row}
                                        onSubmit={onEditPagto}
                                      />,
                                      'Editar Parcela'
                                    )
                                  }
                                />,
                                <GridActionsCellItem
                                  icon={<Delete />}
                                  label="Excluir"
                                  onClick={() =>
                                    openModal(
                                      <DeleteModal
                                        onSubmit={() => onDeletePagto(row)}
                                      />
                                    )
                                  }
                                />,
                              ];
                            },
                          },
                        ]}
                        getRowId={(row) => row?.parcela}
                        {...gridProps}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} textAlign="center">
                <Typography variant="h6" color="primary">
                  Troco:{' '}
                  {(watch('troco') || 0)?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} textAlign="center">
                <Typography variant="h6" color="primary">
                  Total:{' '}
                  {total_pagamento?.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card title="Informações Adicionais">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Dropdown
                  name="vendedor_id"
                  control={control}
                  label="Vendedor"
                  options={drops?.EntidadeTipo?.filter(
                    (f) => f?.tipo === 'VENDEDOR'
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name="observacao"
                  control={control}
                  label="Observação"
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name="dadosadc"
                  control={control}
                  label="Dados Adicionais"
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default OrdemFaturamento;
