import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import { summarizer } from 'utils/functions';
import { OrdensContext } from 'contexts/OrdensContext';
import TableContainer from 'components/TableContainer';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import moment from 'moment';

const Resumo = () => {
  const titulo = 'Resumo da Ordem de Serviço';
  const navigate = useNavigate();
  const { state } = useLocation();
  const { ordem, getLoading, getOrdemResumo } = useContext(OrdensContext);

  useEffect(() => {
    if (state?.documento) {
      getOrdemResumo(state?.documento?.id);
    } else {
      navigate(-1);
    }
  }, [state]);

  const AccordionHeader = ({ title }) => (
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography variant="button">{title}</Typography>
    </AccordionSummary>
  );

  const RenderServiços = ({ servicos = [] }) => {
    const [expanded, setExpanded] = useState(false);
    const props = { expanded, elevation: 3 };
    const title = `Serviços (${summarizer(
      servicos,
      'preco_total'
    )?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })})`;
    return (
      <Grid item xs={12}>
        <Accordion {...props} onChange={(_, v) => setExpanded(v)}>
          <AccordionHeader title={title} />
          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Serviço</TableCell>
                    <TableCell>Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {servicos?.map((s, i) => (
                    <TableRow key={i?.toString()}>
                      <TableCell>{s?.descricao}</TableCell>
                      <TableCell>
                        {(s?.preco_total * 1)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };
  const RenderPeças = ({ pecas = [] }) => {
    const [expanded, setExpanded] = useState(false);
    const props = { expanded, elevation: 3 };
    const title = `Peças (${summarizer(pecas, 'preco_total')?.toLocaleString(
      'pt-br',
      { style: 'currency', currency: 'BRL' }
    )})`;
    return (
      <Grid item xs={12}>
        <Accordion {...props} onChange={(_, v) => setExpanded(v)}>
          <AccordionHeader title={title} />
          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Peça</TableCell>
                    <TableCell>Quantidade</TableCell>
                    <TableCell>Valor Unitário</TableCell>
                    <TableCell>Valor Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pecas?.map((p, i) => (
                    <TableRow key={i?.toString()}>
                      <TableCell>{p?.descricao}</TableCell>
                      <TableCell>{p?.quantidade}</TableCell>
                      <TableCell>
                        {(p?.preco * 1)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>
                        {(p?.preco_total * 1)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };
  const RenderProcessos = ({ processos = [] }) => {
    const [expanded, setExpanded] = useState(false);
    const props = { expanded, elevation: 3 };
    return (
      <Grid item xs={12}>
        <Accordion {...props} onChange={(_, v) => setExpanded(v)}>
          <AccordionHeader title="Processos" />
          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Processo</TableCell>
                    <TableCell>Responsável</TableCell>
                    <TableCell>Agendamento</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {processos?.map((p, i) => {
                    const max = moment.max(
                      p?.Tarefas?.map((t) => moment(t?.datahora))
                    );
                    const recent = p?.Tarefas?.find(
                      (t) =>
                        moment(t?.datahora).format('YYYY-MM-DD HH:mm:ss') ===
                        moment(max).format('YYYY-MM-DD HH:mm:ss')
                    );
                    return (
                      <TableRow key={i?.toString()}>
                        <TableCell>{p?.descricao}</TableCell>
                        <TableCell>{recent?.destino}</TableCell>
                        <TableCell>
                          {recent?.datahora &&
                            moment(recent?.datahora).format(
                              'DD/MM/YYYY [às] HH:mm'
                            )}
                        </TableCell>
                        <TableCell>{recent?.status}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };
  const RenderApontamentosTecnico = ({ apontamentos = [] }) => {
    const [expanded, setExpanded] = useState(false);
    const props = { expanded, elevation: 3 };

    const title = `Apontamentos por Técnico`;
    return (
      <Grid item xs={12}>
        <Accordion {...props} onChange={(_, v) => setExpanded(v)}>
          <AccordionHeader title={title} />
          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Executante</TableCell>
                    <TableCell>Tempo (minutos)</TableCell>
                    <TableCell>Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {apontamentos?.map((a, i) => (
                    <TableRow key={i?.toString()}>
                      <TableCell>{a?.executante}</TableCell>
                      <TableCell>{a?.tempo}</TableCell>
                      <TableCell>
                        {(a?.valor * 1)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };
  const RenderApontamentos = ({ apontamentos = [] }) => {
    const [expanded, setExpanded] = useState(false);
    const props = { expanded, elevation: 3 };
    const newApontamentos = apontamentos?.map((a) => ({
      ...a,
      valor_total:
        a?.valor_hora *
        moment.duration(moment(a?.fim).diff(moment(a?.inicio))).asHours(),
    }));
    const title = `Apontamentos (${summarizer(
      newApontamentos,
      'valor_total'
    )?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })})`;
    return (
      <Grid item xs={12}>
        <Accordion {...props} onChange={(_, v) => setExpanded(v)}>
          <AccordionHeader title={title} />
          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Executante</TableCell>
                    <TableCell>Início</TableCell>
                    <TableCell>Fim</TableCell>
                    <TableCell>Tempo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newApontamentos?.map((a, i) => (
                    <TableRow key={i?.toString()}>
                      <TableCell>{a?.executante}</TableCell>
                      <TableCell>
                        {a?.inicio &&
                          moment(a?.inicio).format('DD/MM/YYYY [às] HH:mm')}
                      </TableCell>
                      <TableCell>
                        {a?.fim &&
                          moment(a?.fim).format('DD/MM/YYYY [às] HH:mm')}
                      </TableCell>
                      <TableCell>
                        {a?.fim &&
                          moment
                            .duration(moment(a?.fim).diff(moment(a?.inicio)))
                            .days() +
                            'd:' +
                            moment
                              .duration(moment(a?.fim).diff(moment(a?.inicio)))
                              .hours() +
                            'h:' +
                            moment
                              .duration(moment(a?.fim).diff(moment(a?.inicio)))
                              .minutes() +
                            'm:' +
                            moment
                              .duration(moment(a?.fim).diff(moment(a?.inicio)))
                              .seconds() +
                            's'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };
  const RenderMateriais = ({ materiais = [] }) => {
    const [expanded, setExpanded] = useState(false);
    const props = { expanded, elevation: 3 };
    const title = `Materiais de Apoio (${summarizer(
      materiais,
      'preco_total'
    )?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })})`;
    return (
      <Grid item xs={12}>
        <Accordion {...props} onChange={(_, v) => setExpanded(v)}>
          <AccordionHeader title={title} />
          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Material</TableCell>
                    <TableCell>Data de Envio</TableCell>
                    <TableCell>Quantidade</TableCell>
                    <TableCell>Valor Unitário</TableCell>
                    <TableCell>Valor Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materiais?.map((m, i) => (
                    <TableRow key={i?.toString()}>
                      <TableCell>{m?.produto}</TableCell>
                      <TableCell>
                        {m?.dtemissao &&
                          moment(m?.dtemissao).format('DD/MM/YYYY [às] HH:mm')}
                      </TableCell>
                      <TableCell>{m?.qtd_total}</TableCell>
                      <TableCell>
                        {(m?.preco * 1)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell>
                        {(m?.preco_total * 1)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };
  const RenderFinanceiro = ({ financeiro = [], lancamentos }) => {
    const [expanded, setExpanded] = useState(false);
    const [tab, setTab] = useState(0);
    const props = { expanded, elevation: 3 };
    const total =
      (summarizer(financeiro, 'valor') || 0) +
      (summarizer(lancamentos, 'valor') || 0);
    const title = `Financeiro (${total?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })})`;
    return (
      <Grid item xs={12}>
        <Accordion {...props} onChange={(_, v) => setExpanded(v)}>
          <AccordionHeader title={title} />
          <AccordionDetails>
            <Tabs
              value={tab}
              onChange={(_, v) => setTab(v)}
              variant="fullWidth"
            >
              <Tab label="À VISTA" />
              <Tab label="À PRAZO" />
            </Tabs>
            {tab === 0 && (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Histórico</TableCell>
                      <TableCell>Documento</TableCell>
                      <TableCell>Modelo</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Recebimento</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lancamentos?.map((l, i) => (
                      <TableRow key={i?.toString()}>
                        <TableCell>{l?.historico}</TableCell>
                        <TableCell>{l?.documento}</TableCell>
                        <TableCell>{l?.modelo}</TableCell>
                        <TableCell>
                          {(l?.valor * 1)?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </TableCell>
                        <TableCell>
                          {l?.recebimento &&
                            moment(l?.recebimento).format('DD/MM/YYYY')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {tab === 1 && (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Data de Emissão</TableCell>
                      <TableCell>Documento</TableCell>
                      <TableCell>Modelo</TableCell>
                      <TableCell>Prazo (Dias)</TableCell>
                      <TableCell>Parcela</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Recebimento</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {financeiro?.map((f, i) => (
                      <TableRow key={i?.toString()}>
                        <TableCell>
                          {f?.dtemissao &&
                            moment(f?.dtemissao).format(
                              'DD/MM/YYYY [às] HH:mm'
                            )}
                        </TableCell>
                        <TableCell>{f?.documento}</TableCell>
                        <TableCell>{f?.modelo}</TableCell>
                        <TableCell>{f?.prazo}</TableCell>
                        <TableCell>{f?.parcela}</TableCell>
                        <TableCell>
                          {(f?.valor * 1)?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </TableCell>
                        <TableCell>
                          {f?.recebimento &&
                            moment(f?.recebimento).format(
                              'DD/MM/YYYY [às] HH:mm'
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };
  const RenderAgendamentos = ({ agendamentos = [] }) => {
    const [expanded, setExpanded] = useState(false);
    const props = { expanded, elevation: 3 };
    return (
      <Grid item xs={12}>
        <Accordion {...props} onChange={(_, v) => setExpanded(v)}>
          <AccordionHeader title="Agendamentos" />
          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Data / Hora</TableCell>
                    <TableCell>Processo</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {agendamentos?.map((a, i) => (
                    <TableRow key={i?.toString()}>
                      <TableCell>
                        {a?.datahora &&
                          moment(a?.datahora).format('DD/MM/YYYY [às] HH:mm')}
                      </TableCell>
                      <TableCell>{a?.processo}</TableCell>
                      <TableCell>{a?.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <RenderServiços servicos={ordem?.Servicos} />
          <RenderPeças pecas={ordem?.Pecas} />
          <RenderProcessos processos={ordem?.Processos} />
          <RenderApontamentosTecnico
            apontamentos={ordem?.ApontamentosTecnico}
          />
          <RenderApontamentos apontamentos={ordem?.Apontamentos} />
          <RenderMateriais
            materiais={ordem?.Materiais?.filter((f) => Boolean(f?.qtd_total))}
          />
          <RenderFinanceiro
            financeiro={ordem?.Financeiro?.filter(
              (f) => f?.valor && f?.valor > 0
            )}
            lancamentos={ordem?.Lancamentos}
          />
          <RenderAgendamentos agendamentos={ordem?.Agendamentos} />
        </Grid>
      </Card>
    </Container>
  );
};

export default Resumo;
