import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { OrdensContext } from 'contexts/OrdensContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { GridContext } from 'contexts/GridContext';
import { useDialog } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Input from 'components/Input';
import moment from 'moment';

const AgendamentoModal = ({ processo, documento_id, agenda, callback }) => {
  const { closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { getLoading, getOrdem, ordem } = useContext(OrdensContext);
  const { postGrid, postLoading } = useContext(GridContext);
  const defaultValues = {
    datahora: moment(agenda?.datahora).format('YYYY-MM-DD HH:mm:ss'),
    userdest_id: agenda?.userdest_id || null,
    assunto: agenda?.assunto || processo?.descricao || '',
    tarefa: agenda?.tarefa || processo?.descricao || '',
  };
  const { control, handleSubmit, setValue } = useForm({ defaultValues });

  useEffect(() => {
    if (documento_id) {
      getOrdem(documento_id);
    }
  }, []);

  useEffect(() => {
    if (processo && ordem && ordem?.DocumentoServico?.tecnico_id) {
      setValue('userdest_id', ordem?.DocumentoServico?.tecnico_id);
    }
  }, [ordem]);

  const onSubmit = (values) => {
    const data = {
      ...values,
      id: agenda?.id,
      lido: agenda?.lido || 'NAO',
      documento_id: agenda?.documento_id || documento_id,
      useremit_id: agenda?.useremit_id || user?.id,
      status: agenda?.status || 'NAO INICIADA',
      documento_item_processo_id:
        agenda?.documento_item_processo_id || processo?.id,
    };
    postGrid({
      data,
      rotina: 'Tarefa',
      cb: () => {
        closeDialog();
        if (callback) {
          callback();
        }
      },
    });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          Informações Gerais
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          name="datahora"
          control={control}
          label="Data / Hora"
          type="datetime"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="userdest_id"
          control={control}
          label="Técnico"
          options={drops?.Users}
        />
      </Grid>
      <Grid item xs={12}>
        <Input name="assunto" control={control} label="Assunto" />
      </Grid>
      <Grid item xs={12}>
        <Input
          name="tarefa"
          control={control}
          label="Atividades"
          multiline
          rows={10}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default AgendamentoModal;
